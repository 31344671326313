import React from "react";
import { Link } from "react-router-dom";
import Parse from "parse";

import { CircularProgress, ButtonBase } from "@mui/material";

import WindowTitleComponent from "../WindowTitleComponent";


export default class AlbumList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {data: null};
  }

  async componentDidMount() {
    let currentUser = Parse.User.current();
    if (currentUser) {
      let albums = await Parse.Cloud.run("getFlickrAlbumList");
      this.setState({data: albums});
    } else {
      this.setState({data: {photosets: {photoset: []}, error: "No user"}});
    }
  }

  render() {
    let contents;
    let error = null;
    if (!(this.state.data !== null && this.state.data.photosets !== undefined) ) {
      contents = <div className="photoloading"><CircularProgress/></div>
    }
    else {
      error = this.state.data.error;
      const itemList = this.state.data.photosets.photoset.map(function(item) {
        var url = "https://farm" + item.farm + ".staticflickr.com/" + item.server + "/" + item.primary + "_" + item.secret + "_q.jpg";
        var href= "/photos/"+item.id;
        var name = item.title._content;
        return (

            <ButtonBase component={Link} to={href} key={href} className="albumlink">

              <img alt={name} src={url}/>

              <div>
                <small>{name}</small>
                <small className="nphotos"> {item.count_photos} foto's</small>
              </div>

            </ButtonBase>

        )
      });
      contents = <div className="albumcontainer">
        {itemList}
      </div>
    }
    return <div>
      <WindowTitleComponent title="Foto's" />
      {error || contents}
    </div>
  }
}
