import React from "react";
import LazyLoad from "react-lazy-load"; //this package results in some errors, see https://github.com/loktar00/react-lazy-load/issues/135
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";


export function lazyLoadIfNeeded(title, src, imgClass, lazy, onClick, height=200) {
  if (lazy) {
    return (
        <FadeIn height={height}>
          {onLoad => {
            return <img style={{maxWidth: "100%", maxHeight: "75vh"}}
                        className={imgClass}
                        alt={title}
                        src={src}
                        onLoad={onLoad}
                        onClick={onClick}
            />
          }}
        </FadeIn>
    );
  } else {
    return <img className={imgClass} alt={title} src={src} />;
  }
}

class FadeIn extends React.Component {
  state = {
    loaded: false
  };
  onLoad = () => this.setState({ loaded: true });

  render() {
    const duration = 180;

    const defaultStyle = {
      transition: `opacity ${duration}ms ease-in-out`,
      opacity: 0,
      height: "100%"
    };

    const transitionStyles = {
      entering: { opacity: 1 },
      entered: { opacity: 1 },
      exiting: { opacity: 0 },
      exited: { opacity: 0 }
    };
    const { height, children } = this.props,
        { loaded } = this.state;
    return (
        <LazyLoad height={height} offset={200} debounce={false}>
          <Transition in={loaded} timeout={duration}>
            {state => <div style={{ ...defaultStyle, ...transitionStyles[state] }}>{children(this.onLoad)}</div>}
          </Transition>
        </LazyLoad>
    );
  }
}
FadeIn.propTypes = {
  height: PropTypes.number,
  children: PropTypes.func
};

export default lazyLoadIfNeeded;
