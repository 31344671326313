import React, { useEffect, useState, useContext } from "react";
import Parse from "parse";

import { Card, CardActions, Button } from "@mui/material";

import CreateCommentComponent from "./CreateCommentComponent";
import CommentContent from "./CommentContent";
import { SettingsContext } from "../Settings";

const CommentsComponent = (props) => {
  const context = useContext(SettingsContext);

  let borderBot, borderLeft;
  if(!context.themeDarkmode) {
    borderBot = "1px solid #DDD";
    borderLeft = "2px solid #DDD";
  } else {
    borderBot = "1px solid #404859";
    borderLeft = "2px solid #404859";
  }

  const [comments, setComments] = useState([]);

  const [reactionField, setReactionField] = useState("");

  useEffect(() => {

    let query = new Parse.Query("Comment");
    query.equalTo("parentType", props.parentType);
    query.equalTo("parentId", props.parentId);
    query.findAll({}).then(setComments);
    query.subscribe().then(subscription => {
      subscription.on('create', (comment) => {
        setComments(oldcomments => oldcomments.concat(comment));
      });
      subscription.on('update', (comment) => {
        setComments(oldcomments => oldcomments.map(c => {
          if(c.id === comment.id) {
            return comment;
          }
          return c;
        }))
      })
      subscription.on('delete', (comment) => {
        setComments(oldcomments => oldcomments.filter(c => {return c.id !== comment.id}));
      });
    });

  }, [props.parentId, props.parentType]);

  return (
    <div className="comments">
      <CreateCommentComponent parentId={props.parentId} parentType={props.parentType}/>
      {(function() {
        let groups = comments.reduce((r,c) => {
          r[c.get("nestedId")] = [...(r[c.get("nestedId")] || []), c];
          return r;
        }, {undefined: []});
        return groups[undefined].map(comment => {
              let c = comment.toJSON();
              return (
                  <Card key={c.objectId} style={{
                    borderBottom: borderBot,
                    borderRadius: 0,
                    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.33)",
                  }}>
                    <CommentContent c={c} comment={comment} reactionHandler={setReactionField}/>


                    {groups[c.objectId] == null ? "" : groups[c.objectId].map(comment2 => {
                      let c2 = comment2.toJSON();
                      return (
                          <Card key={c2.objectId} style={{
                            borderBottom: borderBot,
                            borderRadius: 0,
                            boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0, 0, 0, 0.33)",
                            marginLeft: 32,
                            borderLeft: borderLeft
                          }}>
                            <CommentContent c={c2} comment={comment2} reactionHandler={setReactionField} nested={true} />
                          </Card>
                      )
                    })}
                    <Button style={{marginLeft: 28, marginBottom: 5, marginTop:5}} onClick={() => setReactionField(c.objectId)}>REAGEER</Button>
                    {reactionField === c.objectId && <CreateCommentComponent style={{margin: "0px 16px 16px 28px"}} parentId={props.parentId} parentType={props.parentType} nestedId={c.objectId} />}
                    <CardActions style={{padding: "0px 8px 8px 8px"}}>

                    </CardActions>
                  </Card>
              )
            }
        )})()}
    </div>
  );
}

export default CommentsComponent;
