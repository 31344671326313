import React from "react";
import { Route } from "react-router-dom";
import Parse from "parse";

import De37Component from "../de37/De37Component";
import { OnlineDetector } from "../OnlineContext";

import EventGrid from "./EventGrid";
import CalendarComponent from "./CalendarComponent";
import EditEventComponent from "./EditEventComponent";
import RegisterComponent from "./RegisterComponent";

class EventsComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			eventsQuery: null,
			events: [],
			registrations: [],
			editDoc: null,
			registerEvent: null,
			newEvent: null,
			eventKey: null,
			filterState: {
				status: "",
				search: "",
			},
		};
	}

	componentDidMount() {
		this.startAllQueries();
	}

	async queryEvents() {
		const setEvents = (events) => this.setState({ events: events });
		const setEventsQuery = (query) => this.setState({ query: query });
		const ClassObject = Parse.Object.extend("Event");

		let query = new Parse.Query(ClassObject);
		if (!window.navigator.onLine) {
			query.fromLocalDatastore();
		}
		query.ascending("startDate");
		query.limit(1000);
		query.greaterThan(
			"endDate",
			new Date(new Date().setDate(new Date().getDate() - 548))
		); //get all events for the calendar starting 1,5 years ago
		let results = [];
		try {
			results = await query.find();
		} catch (error) {
			this.props.handleParseError(error);
			return;
		}

		Parse.Object.pinAll(results);

		setEventsQuery(query);
		setEvents(results);
		const subscription = await query.subscribe();

		subscription.on("create", (object) => {
			//object created
			const evts = this.state.events;
			let contains = false;
			for (let i of evts) {
				if (i.id === object.id) {
					contains = true;
				}
			}
			if (!contains) evts.push(object);
			setEvents(evts);
		});

		subscription.on("update", (object) => {
			const evts = this.state.events;
			for (let i of evts) {
				if (i.id === object.id) {
					evts[evts.indexOf(i)] = object;
					//object updated
					break;
				}
			}
			setEvents(evts);
		});

		subscription.on("delete", (object) => {
			const evts = this.state.events;
			for (let i of evts) {
				if (i.id === object.id) {
					evts.splice(evts.indexOf(i), 1);
					//object deleted
				}
			}
			setEvents(evts);
		});
	}

	async reQueryEvents() {
		const setEvents = (events) => this.setState({ events: events });
		if (this.state.eventsQuery) {
			let results;
			try {
				results = await this.state.eventsQuery.find();
			} catch (error) {
				this.props.handleParseError(error);
				return;
			}

			const evts = [];
			for (let i = 0; i < results.length; i++) {
				const object = results[i];
				evts.push(object);
			}
			setEvents(evts);
			//requery: succes
		} else {
			await this.queryEvents();
		}
	}

	async queryRegistrations() {
		const setRegistrations = (regs) => this.setState({ registrations: regs });
		if (Parse.User.current()) {
			let query1 = new Parse.Query("Registration");
			query1.equalTo("uid", Parse.User.current().id);
			let query2 = new Parse.Query("Registration");
			query2.containsAll("users", [Parse.User.current().id]);
			let query = Parse.Query.or(query1, query2);
			let results;
			try {
				results = await query.findAll({});
			} catch (error) {
				this.props.handleParseError(error);
				return;
			}
			setRegistrations(results);
		}
	}

	startAllQueries() {
		this.queryEvents();
		this.queryRegistrations();
	}

	refresh() {
		this.reQueryEvents();
	}

	render() {
		const setRegistrations = (regs) => this.setState({ registrations: regs });
		const setRegisterEvent = (event) => this.setState({ registerEvent: event });
		const editEvent = (doc) =>
			this.setState({ editDoc: doc, eventKey: doc.id });
		const setNewEvent = (event) =>
			this.setState({ newEvent: event, eventKey: !this.state.eventKey });
		const handleEditEventClose = () =>
			this.setState({ editDoc: null, newEvent: null, eventKey: false });
		const handleEditEventOpen = () =>
			this.setState({ newEvent: {}, eventKey: !this.state.eventKey });
		const handleFilterChange = (e) => this.setState({ filterState: e });
		const removePostEvent = () => this.setState({ postEvent: null });
		const makePost = (event) => this.setState({ postEvent: event });

		const { events, registrations, editDoc, registerEvent, newEvent } =
			this.state;

		const isRegisteredFor = (id) =>
			this.state.registrations.some((val) => val.get("eventId") === id);

		let sortedEvents = events.map((i) => {
			let data = i.toJSON();
			data.id = data.objectId;
			data.doc = i;
			data.startDate = new Date(data.startDate.iso || data.startDate);
			data.endDate = new Date(data.endDate.iso || data.endDate);
			return data;
		});
		sortedEvents.sort((a, b) =>
			a.startDate > b.startDate ? 1 : b.startDate > a.startDate ? -1 : 0
		);

		return (
			<div>
				{Parse.User.current() != null ? <></> : null}

				<Route
					path={["/de37/:id", "/de37"]}
					className="route-element"
					render={(routeProps) => {
						if (Parse.User.current() == null) return null;
						return (
							<De37Component
								user={Parse.User.current()}
								Parse={Parse}
								cardId={routeProps.match.params.id}
								events={sortedEvents}
								routeProps={routeProps}
								postEvent={this.state.postEvent}
								removePostEvent={removePostEvent}
							/>
						);
					}}
				/>
				<Route
					exact
					path={this.props.matchWithSubpages(["/events"])}
					className="route-element"
					render={(routeProps) => (
						<EventGrid
							sortedEvents={sortedEvents}
							isRegisteredFor={isRegisteredFor}
							editEvent={editEvent}
							routeProps={routeProps}
							user={Parse.User.current()}
							handleRegisterEvent={setRegisterEvent}
							filterState={this.state.filterState}
							handleFilterChange={handleFilterChange}
							makePost={makePost}
						/>
					)}
				/>
				<Route
					path="/calendar"
					render={(routeProps) => (
						<CalendarComponent
							events={sortedEvents}
							isRegisteredFor={isRegisteredFor}
							createEvent={setNewEvent}
							editEvent={editEvent}
							handleRegisterEvent={setRegisterEvent}
							user={Parse.User.current()}
							routeProps={routeProps}
							updateAppTitle={this.props.updateAppTitle}
							makePost={makePost}
						/>
					)}
				/>
				<Route
					exact
					path={this.props.matchWithSubpages(["/events", "/calendar"])}
					className="route-element"
					render={() => (
						<OnlineDetector
							render={(online) => (
								<EditEventComponent
									className="add-fab"
									doc={editDoc}
									newEvent={newEvent}
									online={online}
									handleClose={handleEditEventClose}
									handleClickOpen={handleEditEventOpen}
									key={this.state.eventKey}
								/>
							)}
						/>
					)}
				/>

				<RegisterComponent
					event={registerEvent}
					ownRegistrations={registrations}
					setRegistrations={setRegistrations}
					setRegisterEvent={setRegisterEvent}
				/>
			</div>
		);
	}
}

export default EventsComponent;
