import React from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";


const AreYouSure = (props) => {

  const handleYes = () => {
    props.yes();
  }

  const handleNo = () => {
    props.no();
  }

  return (
      <Dialog open={!!props.open} onClose={handleNo}>
        <DialogTitle>Weet je het zeker?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text ? props.text : "Je staat op het punt een niet omkeerbare actie te doen."}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleYes} color="primary">Ja</Button>
          <Button onClick={handleNo} color="primary">Annuleer</Button>
        </DialogActions>
      </Dialog>
  )
}

export default AreYouSure;