import React, { Component } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Parse from "parse";

import {
	Snackbar,
	IconButton,
	ThemeProvider,
	StyledEngineProvider,
	createTheme,
} from "@mui/material";
import "mobile-pull-to-refresh/dist/styles/material/style.css";
import TodayIcon from "@mui/icons-material/Today";
import HomeIcon from "@mui/icons-material/Home";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import BugReportIcon from "@mui/icons-material/BugReport";
import HearingIcon from "@mui/icons-material/Hearing";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import SettingsIcon from "@mui/icons-material/Settings";
import EmailIcon from "@mui/icons-material/Email";
import RefreshIcon from "@mui/icons-material/Refresh";
import EuroIcon from "@mui/icons-material/Euro";
import { WorkOutline } from "@mui/icons-material";

import { purpleFriday } from "./FormatDate";
import { SettingsProvider, SettingsContext } from "./Settings";
import OnlineProvider, { OnlineDetector } from "./OnlineContext";
import DesdAppBar from "./DesdAppBar";
import ResponsiveNavigation from "./ResponsiveNavigation";
import SubmenuComponent from "./SubmenuComponent";
import LoginComponent from "./LoginComponent";
import FeedbackComponent from "./FeedbackComponent";
import GehoordComponent from "./GehoordComponent";
import EventsComponent from "./events/EventsComponent";
import PhotoComponent from "./photos/PhotoComponent";
import BestuursbusComponent from "./BestuursbusComponent";
import withMediaQuery from "./withMediaQuery";
import SettingsComponent from "./SettingsComponent";
import WindowTitleComponent from "./WindowTitleComponent";
import * as utils from "./DataUtils";
import registerServiceWorker from "./registerServiceWorker";
import "./App.css";
import "./App-dark.css";
import Config from "./config";

const menu = {
	mainmenu: [
		{
			label: "De 37",
			to: "/de37",
			icon: <HomeIcon />,
			onlyOnline: true,
			hasSubpage: true,
		},
		{
			label: "Activiteiten",
			to: "/events",
			icon: <ViewModuleIcon />,
			onlyOnline: false,
			hasSubpage: true,
		},
		{
			label: "Foto's",
			to: "/photos",
			icon: <PhotoLibraryIcon />,
			onlyOnline: true,
			hasSubpage: true,
		},
	],
	submenu: [
		{
			label: "Agenda",
			to: "/calendar",
			icon: <TodayIcon />,
			onlyOnline: false,
			hasSubpage: true,
		},
		{
			label: "Gehoord",
			to: "/gehoord",
			icon: <HearingIcon />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Feedback/Bug melden",
			to: "/feedback",
			icon: <BugReportIcon />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Bestuursbus",
			to: "/bestuursbus",
			icon: <AirportShuttleIcon />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Nieuwsbriefstukje inzenden",
			to: "https://administration.desda.org/newsletter",
			referer: true,
			icon: <EmailIcon />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Declaratie inzenden",
			to: "https://administration.desda.org/declaraties",
			referer: true,
			icon: <EuroIcon />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Bedrijfsprofielen en vacatures",
			to: "https://www.desda.org/carriere/bedrijfsprofielen/",
			referer: true,
			icon: <WorkOutline />,
			onlyOnline: true,
			hasSubpage: false,
		},
		{
			label: "Instellingen",
			to: "/settings",
			icon: <SettingsIcon />,
			onlyOnline: false,
			hasSubpage: false,
		},
	],
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: null,
			appTitle: "DesdApp",
			showReloadSnackbar: false,
			updateAvailable: false,
		};

		this.EventsComponentRef = React.createRef();
		this.messagesRef = React.createRef();
		if (Config.useLocalServer) {
			Parse.initialize("myAppId");
			Parse.serverURL = "http://localhost:1337/1";
		} else {
			Parse.initialize(
				"Df64UIiwB6ix3y9rp10AQf6LMee4hQMT7sQYViBY",
				"i0AVlqbKBnE0phWPD1MiEcKKUhYBe5MQeQKizMTM"
			);
			Parse.serverURL =
				"https://pg-app-g36mbvirrcr4jqzwa3y6e1eyuah3aj.scalabl.cloud/1/";
		}
		Parse.enableLocalDatastore();
		this.performRefresh = this.performRefresh.bind(this);
	}

	checkIsLoggedIn = () => {
		let currentUser = Parse.User.current();
		if (currentUser) {
			this.setState({ user: currentUser });
		} else {
			const loc = window.location.pathname;
			this.setState({ user: null });

			if (!window.location.pathname.endsWith("/login"))
				window.location.replace("/login?req=" + loc);
		}
	};

	initializeSWAndPullToRefresh() {
		let app = this;
		registerServiceWorker(app);

		// pullToRefresh({
		//   container: document.querySelector(".container"),
		//   animates: ptrAnimatesMaterial,

		//   refresh() {
		//     return new Promise(resolve => {
		//       app.performRefresh(true).then(resolve);
		//     });
		//   }
		// });
	}

	componentDidMount() {
		this.initializeSWAndPullToRefresh();
		this.checkIsLoggedIn();
	}

	askForReload = () => {
		this.setState({ showReloadSnackbar: true, updateAvailable: true });
	};

	async performRefresh(originIsPullToRefresh = false) {
		if (this.state.updateAvailable) this.performReload();
		else {
			await utils.updateSW();
			if (!originIsPullToRefresh)
				if (this.messagesRef.current) this.messagesRef.current.refresh();
			if (this.EventsComponentRef.current)
				this.EventsComponentRef.current.refresh();
		}
	}

	handleParseError = (err) => {
		switch (err.code) {
			case Parse.Error.INVALID_SESSION_TOKEN:
				this.onLogOut();
				break;
			default:
				break;
		}
	};

	performReload() {
		window.location.reload();
	}
	onLoggedIn = (token, req) => {
		if (Config.useLocalAuth) {
			this.setState({ user: Parse.User.current() });
			if (!req) req = "/";
			window.location.replace(req);
			return;
		}

		Parse.User.become(token)
			.then((user) => {
				this.setState({ user: user });
				if (!req) req = "/";

				window.location.replace(req);
			})
			.catch((error) => {
				if (!window.location.pathname.endsWith("/login"))
					window.location.replace("/login?req=" + req);
				console.log(error);
			});
	};

	onLogOut = () => {
		Parse.User.logOut().then(() => {
			this.setState({ user: Parse.User.current() }); // this will now be null
			window.location.replace("/login");
		});
	};

	updateAppTitle = (title) => {
		this.setState({ appTitle: title });
	};

	handleChange = (event, value) => {
		this.setState({ value: value });
	};

	updateState = (obj) => {
		this.setState(obj);
	};

	handleReloadSnackbarClose = () => {
		this.setState({ showReloadSnackbar: false });
	};

	matchWithSubpages = (arr, param = "id") => {
		let subpages = [];
		for (let url of arr) {
			let menuItem = menu.mainmenu
				.concat(menu.submenu)
				.find((x) => "/" + x.to.split("/")[1] === url);
			if (menuItem && menuItem.hasSubpage) {
				subpages.push(url + "/:" + param);
			}
		}
		return subpages.concat(arr);
	};

	makeTheme(context) {
		let color = "#1A237E";
		if (purpleFriday(new Date())) {
			color = "#9c27b0";
		} else if (context.themeDarkmode) {
			color = "#1565c0";
		}

		return createTheme({
			palette: {
				mode: context.themeDarkmode ? "dark" : "light",
				primary: {
					main: color,
				},
				secondary: {
					main: color,
				},
				background: {
					paper: context.themeDarkmode ? "#1F1F1F" : "#FFF",
				},
			},
			typography: {
				useNextVariants: true,
			},
		});
	}

	render() {
		const useAppDrawer = this.props.mediaQuery;

		return (
			<SettingsProvider>
				<OnlineProvider>
					<SettingsContext.Consumer>
						{(context) => (
							<StyledEngineProvider injectFirst>
								<ThemeProvider theme={this.makeTheme(context)}>
									{context.themeDarkmode
										? document.querySelector("body").classList.add("darkmode")
										: document
												.querySelector("body")
												.classList.remove("darkmode")}
									<WindowTitleComponent />

									<Router>
										<div className="App Site">
											<DesdAppBar
												useAppDrawer={useAppDrawer}
												appTitle={this.state.appTitle}
												performRefresh={this.performRefresh}
											/>

											<div className="Site-content">
												<Switch>
													<Route
														path={["/settings"]}
														render={() =>
															this.state.user && (
																<SettingsComponent
																	user={this.state.user}
																	onLogOut={this.onLogOut}
																/>
															)
														}
													/>
													<Route
														path={["/login"]}
														render={() => (
															<LoginComponent
																onLoggedIn={this.onLoggedIn}
																onLogOut={this.onLogOut}
																loggedIn={this.state.user !== null}
																user={this.state.user}
																parse={Parse}
															/>
														)}
													/>

													<Route
														path={["/events", "/calendar", "/de37"]}
														render={() => (
															<EventsComponent
																handleParseError={this.handleParseError}
																updateAppTitle={this.updateAppTitle}
																matchWithSubpages={this.matchWithSubpages}
																ref={this.EventsComponentRef}
															/>
														)}
													/>
													<Route
														path={this.matchWithSubpages(["/photos"])}
														render={(routeProps) => (
															<SimpleReactLightbox>
																<PhotoComponent
																	user={this.state.user}
																	routeProps={routeProps}
																	updateAppTitle={this.updateAppTitle}
																	albumId={routeProps.match.params.id}
																/>
															</SimpleReactLightbox>
														)}
													/>

													<Route
														path="/feedback"
														render={() => <FeedbackComponent />}
													/>

													<Route
														path="/gehoord"
														render={() => <GehoordComponent />}
													/>

													<Route
														path="/bestuursbus"
														render={() => <BestuursbusComponent />}
													/>

													{!useAppDrawer ? (
														<Route
															path="/submenu"
															render={(routeProps) => (
																<OnlineDetector
																	render={(online) => (
																		<SubmenuComponent
																			online={online}
																			menu={menu.submenu}
																		/>
																	)}
																/>
															)}
														/>
													) : null}
													<Route
														path="*"
														render={() => <Redirect to="/de37" />}
													/>
												</Switch>
											</div>

											{this.state.user != null ? (
												<ResponsiveNavigation
													menu={menu}
													useAppDrawer={useAppDrawer}
													matchWithSubpages={this.matchWithSubpages}
												/>
											) : null}
										</div>
									</Router>

									<Snackbar
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										open={this.state.showReloadSnackbar}
										autoHideDuration={300000}
										onClose={this.handleReloadSnackbarClose}
										ContentProps={{
											"aria-describedby": "message-id",
										}}
										message={
											<span id="message-id">
												Er is een nieuwe versie van de app beschikbaar. Klik
												hier om te updaten.
											</span>
										}
										action={[
											<IconButton
												key="close"
												aria-label="Close"
												color="inherit"
												style={{ padding: "4px" }}
												onClick={this.performReload}
												size="large"
											>
												<RefreshIcon />
											</IconButton>,
										]}
									/>
								</ThemeProvider>
							</StyledEngineProvider>
						)}
					</SettingsContext.Consumer>
				</OnlineProvider>
			</SettingsProvider>
		);
	}
}

export default withMediaQuery("(min-width:1224px)")(App);
