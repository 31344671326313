import React, { useState } from "react";
import ReactMde from "react-mde";
import { TextField } from "@mui/material";
import ImageInputComponent from "../ImageInputComponent";
import { DateTimePicker } from "@mui/x-date-pickers";
import linkifyMarkdifySanitize from "../YouTube";

const EditBasicEventDataComponent = props => {
    const [selectedMdTab, setSelectedMdTab] = useState("write");
    let event = props.event;
    let setEvent = props.setEvent;
    let handleChange = props.handleChange;
    let setUploadingImage = props.setUploadingImage;
    let setChangedImg = props.setChangedImg;
    let handleTitleChange = event => {
        setEvent({ title: event.target.value.slice(0, 50) });
    };
    let handleStartDateChange = date => {
        if (event.endDate < date) {
            //endDate cannot be before startDate
            setEvent({ startDate: date, endDate: date });
        } else setEvent({ startDate: date });
    };

    let handleEndDateChange = date => {
        if (event.startDate > date) {
            //startDate cannot be after endDate
            setEvent({ startDate: date, endDate: date });
        } else setEvent({ endDate: date });
    };
    const setDescription = (desc) => setEvent({ description: desc });
    return <>
        <TextField label="Evenement titel" value={event.title} onChange={handleTitleChange} variant="outlined" />
        <ImageInputComponent
            img={event.img}
            onDrop={(file) => { setEvent({ img: file }); }}
            setUploadingImage={setUploadingImage}
            onImgChange={setChangedImg}
        />
        <div className="flex-row">
            <DateTimePicker
                onChange={handleStartDateChange}
                renderInput={() => <TextField variant="standard" label="Begindatum" /> }
                value={event.startDate}
                ampm={false}
            />
            <DateTimePicker
                onChange={handleEndDateChange}
                renderInput={() => <TextField variant="standard" label="Einddatum" /> }
                value={event.endDate}
                ampm={false}
                variant="outlined"
            />
        </div>
        <TextField variant="outlined" label="Locatie" value={event.location} onChange={handleChange("location")} />
        <ReactMde
            value={event.description}
            onChange={setDescription}
            selectedTab={selectedMdTab}
            onTabChange={setSelectedMdTab}
            l18n={{ write: "Bewerken", preview: "Voorbeeld" }}
            generateMarkdownPreview={markdown =>
                Promise.resolve(linkifyMarkdifySanitize(markdown)[0])
            }
        />
        <TextField
            label="Beheerders (gescheiden door komma's)"
            variant="outlined"
            placeholder="Landsman, Koelink, Marcut, ..."
            value={event.organizers}
            onChange={handleChange("organizers")}
            inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
            }}
        />
    </>;
};

export default EditBasicEventDataComponent;