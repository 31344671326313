import React from "react";
import { Helmet } from "react-helmet";


class WindowTitleComponent extends React.Component {
  render() {
    let title;
    if (this.props.title) {
      title = this.props.title + " | DesdApp";
    } else {
      title = "DesdApp";
    }
    return <Helmet>
      <title>{title}</title>
    </Helmet>
  }
}

export default (WindowTitleComponent);