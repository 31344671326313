import React, { Component } from "react";
import Parse from "parse";
import classnames from "classnames";
import PropTypes from "prop-types";

import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Collapse,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Menu,
	MenuItem,
	Snackbar,
	ListItemIcon,
	Tabs,
	Tab,
	Paper,
	Tooltip,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PostAddIcon from "@mui/icons-material/PostAdd";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import SecurityIcon from "@mui/icons-material/Security";
import CloseIcon from "@mui/icons-material/Close";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import TodayIcon from "@mui/icons-material/Today";
import ShareIcon from "@mui/icons-material/Share";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import HowToRegIcon from "@mui/icons-material/HowToReg";

import formatDate from "../FormatDate";
import lazyLoadIfNeeded from "../FadeIn";
import { linkifyMarkdifySanitize } from "../YouTube";
import WindowTitleComponent from "../WindowTitleComponent";
import CommentsComponent from "../de37/CommentsComponent";
import { SettingsContext } from "../Settings";
import { IfOnline } from "../OnlineContext";
import AreYouSure from "../AreYouSureComponent";

import { unescapeString } from "./fieldescaping";
import EetlijstComponent from "./EetlijstComponent";
import { getAllRegFields } from "./RegistrationFields";
import PencilIconComponent from "./PencilIconComponent";

import "./EventCard.css";

class EventCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			registrations: [],
			subscription: null,
			hasQueriedRegistrationsOnce: false,
			openDialog: false,
			settingsMenu: null,
			currentTab: 0,
		};
	}

	componentDidMount() {
		this._mounted = true;
		if (this.props.doc !== null) {
			if (this.props.expanded === true) {
				this.setState({ hasQueriedRegistrationsOnce: true });
				this.queryRegistrations();
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		//om het aantal queries te verkleinen querien we pas als de beschrijving wordt expand.
		if (
			!prevState.expanded &&
			this.state.expanded &&
			!this.state.hasQueriedRegistrationsOnce
		) {
			this.setState({ hasQueriedRegistrationsOnce: true });
			this.queryRegistrations();
		}

		//als (door een liveQuery) de gegevens van het evenement worden geupdate of als de gebruiker admin wordt,
		// of zelf registreert, altijd opnieuw registraties querieën
		if (
			(this.props.doc !== prevProps.doc ||
				this.props.isRegistered !== prevProps.isRegistered) &&
			this.props.doc !== null
		) {
			this.queryRegistrations();
		}
	}

	componentWillUnmount() {
		this._mounted = false;
		if (this.state.subscription) {
			this.state.subscription.unsubscribe();
		}
	}

	async queryRegistrations() {
		if (this.props.doc == null) return;
		if (
			!this.props.event.openForRegistration &&
			!(
				this.props.doc.has("hasRegistrations") &&
				this.props.doc.get("hasRegistrations")
			)
		)
			return;

		if (Parse.User.current() && this.canUserEditEvent()) {
			const query = new Parse.Query("Registration");
			query.equalTo("eventId", this.props.doc.id);
			query.limit(1000);
			query.ascending("createdAt");
			const results = await query.find();

			// Do something with the returned Parse.Object values
			const regs = results.map((object) => object.toJSON());

			if (!this._mounted) return;

			this.setState({ registrations: regs });

			const subscription = await query.subscribe();

			subscription.on("create", (object) => {
				let regs = this.state.registrations;
				let contains = false;
				for (let i of regs) {
					if (i.objectId === object.id) {
						contains = true;
					}
				}
				if (!contains) regs.push(object.toJSON());
				this.setState({ registrations: regs });
			});

			subscription.on("update", (object) => {
				let regs = this.state.registrations;
				for (let i of regs) {
					if (i.objectId === object.id) {
						regs[regs.indexOf(i)] = object.toJSON();
						// reg updated
						break;
					}
				}
				this.setState({ registrations: regs });
			});

			subscription.on("delete", (object) => {
				let regs = this.state.registrations;
				for (let i of regs) {
					if (i.objectId === object.id) {
						regs.splice(regs.indexOf(i), 1);
						//reg deleted
					}
				}
				this.setState({ registrations: regs });
			});
		} else {
			//show nonPersonalData
			const params = { eventId: this.props.doc.id };
			const nonPersonalData = await Parse.Cloud.run(
				"getNonPersonalDataFromRegistration",
				params
			);

			if (!this._mounted) return;

			this.setState({ registrations: nonPersonalData });
		}
	}

	settingsMenuOpen = (event) => {
		this.setState({ settingsMenu: event.currentTarget });
	};

	deleteThisEvent = () => {
		if (this.props.doc) {
			this.props.doc.destroy();
		}
	};

	settingsMenuClose = (event, option) => {
		const pad2 = (n) => (n < 10 ? "0" : "") + n;
		this.setState({ settingsMenu: null });
		switch (option) {
			case "edit":
				this.props.editEvent(this.props.doc);
				break;
			case "newsletter":
				const e = this.props.event;
				let url = "https://administration.desda.org/newsletter?";
				url += "locationNL=" + encodeURIComponent(e.location);
				url += "&descriptionNL=" + encodeURIComponent(e.description);
				url +=
					"&startTime=" +
					pad2(e.startDate.getHours()) +
					":" +
					pad2(e.startDate.getMinutes());
				url +=
					"&endTime=" +
					pad2(e.endDate.getHours()) +
					":" +
					pad2(e.endDate.getMinutes());
				url +=
					"&startDate=" +
					e.startDate.getFullYear() +
					"-" +
					pad2(e.startDate.getMonth() + 1) +
					"-" +
					pad2(e.startDate.getDate());
				url +=
					"&endDate=" +
					e.endDate.getFullYear() +
					"-" +
					pad2(e.endDate.getMonth() + 1) +
					"-" +
					pad2(e.endDate.getDate());
				url +=
					"&desdapp=" +
					encodeURIComponent("https://app.desda.org/events/" + e.id);
				url += "&nameNL=" + encodeURIComponent(e.title);
				url +=
					"&submitter=" +
					encodeURIComponent(this.props.user.get("display_name"));
				window.open(url, "_blank");
				break;
			case "37":
				this.props.makePost(this.props.event);
				this.props.routeProps.history.push("/de37/");
				break;
			case "delete":
				this.setState({ deleting: true });
				break;
			default:
				break;
		}
	};

	handleExpandClick = () => {
		this.props.routeProps.history.push("/events/" + this.props.event.id);
	};

	handleShareClick = () => {
		if (navigator.share) {
			navigator
				.share({
					title: this.props.title,
					text: "",
					url: "https://app.desda.org/events/" + this.props.doc.id,
				})
				.catch();
		} else {
			async function copyPageURL(id) {
				try {
					await navigator.clipboard.writeText(
						"https://app.desda.org/events/" + id
					);
					// Page URL copied to clipboard
				} catch (err) {
					//console.error("Failed to copy: ", err);
				}
			}
			copyPageURL(this.props.doc.id);
			this.setState({ snackbarOpen: true });
		}
	};

	handleEetlijstClick = () => {
		this.setState({ eetlijstEvent: this.props.doc });
	};

	handleSnackbarClose = () => {
		this.setState({ snackbarOpen: false });
	};

	handleInterestedClick = () => {
		this.props.handleRegisterEvent(this.props.doc);
	};

	handleTabs = (event, newValue) => {
		this.setState({ currentTab: newValue });
	};

	getFieldIfPossible(reg, field) {
		if (!reg["fields"]) return "-";
		if (!reg["fields"][field]) return "-";

		return reg["fields"][field];
	}

	canUserEditEvent = () => {
		let isAdmin = this.context.isAdmin;

		return (
			this.props.user.id === this.props.event.uid ||
			isAdmin ||
			(this.props.event.organizers &&
				this.props.event.organizers
					.map((x) => x.id)
					.includes(this.props.user.id))
		);
	};

	closeEetlijstComponent = () => {
		this.setState({ eetlijstEvent: null });
	};

	getAllRegFields = (event) => getAllRegFields(event, this.canUserEditEvent());

	checkAndFixImage = (img) => {
		let imgUrl;
		if (img != null) {
			imgUrl = img.url;
		} else {
			imgUrl =
				"https://desdaklimrek.science.ru.nl/wp-content/uploads/desda_kaartje9.png";
		}
		return imgUrl;
	};

	render() {
		const initiallyExpanded = this.props.expanded === true;
		const expanded = initiallyExpanded || this.state.expanded;
		const startDate = formatDate(this.props.event.startDate, true, true);
		const equalDate = ((x, y) =>
			x.getDate() === y.getDate() &&
			x.getMonth() === y.getMonth() &&
			x.getFullYear() === y.getFullYear() &&
			x.getHours() === y.getHours() &&
			x.getMinutes() === y.getMinutes())(
			this.props.event.startDate,
			this.props.event.endDate
		);
		const endDate = !equalDate
			? formatDate(
					this.props.event.endDate,
					true,
					true,
					this.props.event.startDate
			  )
			: "";
		const isRegistered = this.props.isRegistered;
		const deadline = this.props.event.registrationDeadline
			? new Date(this.props.event.registrationDeadline.iso)
			: undefined;
		const opening = this.props.event.registrationOpening
			? new Date(this.props.event.registrationOpening.iso)
			: undefined;
		const now = new Date();
		const openForRegistration =
			this.props.event.openForRegistration &&
			(!deadline || deadline > now) &&
			(!opening || opening < now);
		const regDaysLeft = this.props.event.registrationDeadline
			? (deadline.getTime() - now.getTime()) / (1000 * 3600 * 24)
			: undefined;
		const isArchived =
			new Date(
				this.props.event.endDate.getFullYear(),
				this.props.event.endDate.getMonth(),
				this.props.event.endDate.getDate() + 3,
				4,
				0,
				0
			) < new Date();
		let [sanitizedDescription, youtube] = linkifyMarkdifySanitize(
			this.props.event.description
		);
		let numberOfVisibleButtons =
			(openForRegistration && !isArchived) +
			this.canUserEditEvent() +
			(this.props.event.hasEetlijst && !isArchived) +
			1;
		const showRegistrationsTable =
			((this.canUserEditEvent() && openForRegistration) ||
				(this.state.registrations && this.state.registrations.length > 0)) &&
			!isArchived;
		const regCount = this.props.event.regCount || 0;
		const hadRegistrations =
			regCount > 0 && this.props.event.openForRegistration;
		const showTextWithButton = numberOfVisibleButtons <= 3;

		return (
			<Card className={"EventCard-card"} elevation={0}>
				<div className={"EventCard-imageDiv"}>
					{lazyLoadIfNeeded(
						this.props.event.title,
						this.checkAndFixImage(this.props.event.img),
						"EventCard-image",
						true,
						this.handleExpandClick
					)}

					<Typography
						variant="h6"
						component="h1"
						noWrap
						className="card-title"
						onClick={this.props.expanded ? null : this.handleExpandClick}
					>
						{this.props.event.title}
					</Typography>
				</div>

				<CardContent className="no-padding-bottom">
					<div className="event-time-row">
						{" "}
						<div>
							<TodayIcon className="calendar-icon" />
							<Typography component="p">
								{" "}
								{startDate} {equalDate ? "" : "-"} {endDate}
							</Typography>
						</div>
						<div style={{ display: "flex", flexShrink: 100 }}>
							{" "}
							{this.props.event.location ? (
								<LocationOnIcon className="location-on-icon" />
							) : null}
							<Typography component="p">
								{" "}
								{this.props.event.location}
							</Typography>
						</div>
					</div>

					{opening !== undefined && opening > now && expanded && (
						<div className="event-time-row">
							<div>
								<HowToRegOutlinedIcon />
								<Typography component="p">
									{"Inschrijven vanaf " + formatDate(opening, true, true)}
								</Typography>
							</div>
						</div>
					)}
					{deadline !== undefined && expanded && (
						<div className="event-time-row">
							<div style={{ display: "flex" }}>
								<AlarmIcon />
								<Typography component="p">
									{"Inschrijven voor " + formatDate(deadline, true, true)}
								</Typography>
							</div>
						</div>
					)}
				</CardContent>
				<CardActions className="no-padding-top">
					<>
						<IfOnline>
							{((openForRegistration && !isArchived) || isRegistered) &&
								(showTextWithButton ? (
									<Button
										size="small"
										color="primary"
										onClick={this.handleInterestedClick}
										disabled={!openForRegistration || isArchived}
									>
										{isRegistered ? (
											<HowToRegIcon className={"EventCard-leftIcon"} />
										) : (
											<HowToRegOutlinedIcon className={"EventCard-leftIcon"} />
										)}
										{isRegistered ? "Ingeschreven" : "Inschrijven"}
									</Button>
								) : (
									<IconButton
										aria-label={isRegistered ? "Uitschrijven" : "Inschrijven"}
										color="primary"
										onClick={this.handleInterestedClick}
										disabled={!openForRegistration || isArchived}
									>
										{isRegistered ? (
											<HowToRegIcon className={"EventCard-leftIcon"} />
										) : (
											<HowToRegOutlinedIcon className={"EventCard-leftIcon"} />
										)}
									</IconButton>
								))}

							{/* Inschrijfdeadline reminder */}
							{(deadline !== undefined || opening !== undefined) &&
								!expanded && (
									<IconButton disableRipple={true}>
										<Tooltip
											title={
												<Typography color="inherit">
													{(function () {
														if (opening && opening > now) {
															return `Inschrijving opent ${formatDate(
																opening,
																false,
																false
															)}`;
														}
														if (deadline < now) {
															return "Inschrijfdeadline verlopen.";
														} else {
															if (regDaysLeft < 1) {
																return `Nog ${Math.floor(
																	regDaysLeft * 24
																)} uur om in te schrijven!`;
															} else {
																let daysLeft = Math.floor(regDaysLeft);
																if (daysLeft === 1) {
																	return `Nog één dag om in te schrijven!`;
																} else {
																	return `Nog ${daysLeft} dagen om in te schrijven!`;
																}
															}
														}
													})()}
												</Typography>
											}
											arrow
										>
											<AlarmIcon
												color={
													deadline < now || opening > now
														? "disabled"
														: regDaysLeft > 2
														? "primary"
														: "error"
												}
											/>
										</Tooltip>
									</IconButton>
								)}
						</IfOnline>

						{/* Delen */}
						<IconButton
							aria-label="Delen"
							color="primary"
							onClick={this.handleShareClick}
						>
							<ShareIcon />
						</IconButton>

						<IfOnline>
							{/* Eetlijst */}
							{this.props.event.hasEetlijst && !isArchived && (
								<IconButton
									aria-label="Eetlijst"
									color="primary"
									onClick={this.handleEetlijstClick}
								>
									<FastfoodIcon />
								</IconButton>
							)}

							{/* Voor admins/beheerders */}
							{this.canUserEditEvent() && (
								<>
									<IconButton
										aria-label="Instellingen"
										aria-haspopup="true"
										aria-controls="settings-menu"
										color="primary"
										onClick={this.settingsMenuOpen}
									>
										{/* <MoreVertIcon /> */}
										<PencilIconComponent />
									</IconButton>
									<AreYouSure
										open={this.state.deleting}
										yes={this.deleteThisEvent}
										no={() => this.setState({ deleting: false })}
										text="Weet u zeker dat u dit evenement wilt verwijderen? Dit kan niet ongedaan worden gemaakt."
									/>
									<Menu
										id="settings-menu"
										anchorEl={this.state.settingsMenu}
										open={Boolean(this.state.settingsMenu)}
										onClose={this.settingsMenuClose}
									>
										<MenuItem
											onClick={(event) => this.settingsMenuClose(event, "edit")}
										>
											<ListItemIcon>
												<EditIcon />
											</ListItemIcon>{" "}
											Bewerken
										</MenuItem>
										<MenuItem
											onClick={(event) =>
												this.settingsMenuClose(event, "newsletter")
											}
										>
											<ListItemIcon>
												<EmailIcon />
											</ListItemIcon>{" "}
											Nieuwsbriefstukje maken
										</MenuItem>
										<MenuItem
											onClick={(event) => this.settingsMenuClose(event, "37")}
										>
											<ListItemIcon>
												<PostAddIcon />
											</ListItemIcon>{" "}
											Post op De 37 maken
										</MenuItem>
										<MenuItem
											onClick={(event) =>
												this.settingsMenuClose(event, "delete")
											}
										>
											<ListItemIcon>
												<DeleteIcon />
											</ListItemIcon>{" "}
											Verwijderen
										</MenuItem>
									</Menu>
								</>
							)}
						</IfOnline>

						{this.props.expanded || (
							<IconButton
								className={classnames("EventCard-expand", {
									["EventCard-expandOpen"]: expanded,
								})}
								onClick={this.handleExpandClick}
								aria-expanded={expanded}
								aria-label="Lees meer"
							>
								<InfoIcon />
							</IconButton>
						)}
					</>
				</CardActions>

				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<WindowTitleComponent title={this.props.event.title} />
					<CardContent>
						<Typography
							className="markdown-rendered"
							component="p"
							dangerouslySetInnerHTML={{
								__html: sanitizedDescription,
							}}
						/>
						{youtube !== null ? youtube : null}
						{/* TODO: Custom Tab component met een getalletje voor hoeveel inschrijven en reacties er zijn */}
						<Paper square>
							<Tabs
								value={this.state.currentTab}
								indicatorColor="primary"
								textColor="primary"
								onChange={this.handleTabs}
							>
								<Tab label="Reacties" />
								{showRegistrationsTable ? <Tab label="Inschrijvingen" /> : null}
								{hadRegistrations && isArchived ? (
									<Tab
										label={
											"Er " +
											(regCount === 1 ? "was " : "waren ") +
											regCount +
											" inschrijving" +
											(regCount !== 1 ? "en" : "")
										}
										disabled
									/>
								) : null}
							</Tabs>
						</Paper>

						{/* Reacties */}
						<div
							hidden={this.state.currentTab !== 0}
							style={{ paddingTop: 20 }}
						>
							<CommentsComponent
								parentId={this.props.event.id}
								parentType="Event"
							/>
						</div>

						{/* Inschrijvingen */}
						<div hidden={this.state.currentTab !== 1}>
							{showRegistrationsTable ? (
								<IfOnline>
									<div className={"EventCard-tableContainer"}>
										<Table className={"EventCard-table"}>
											<TableHead>
												<TableRow>
													<TableCell
														padding="none"
														style={{ paddingLeft: "8px" }}
													/>
													{!this.props.doc.get("teamRegistration") ? (
														<TableCell>Naam</TableCell>
													) : null}
													{this.getAllRegFields(this.props.doc).map((x) => {
														let [field, personal] = x;
														return (
															<TableCell align="right" key={field}>
																<div
																	style={{
																		display: "flex",
																		alignItems: "center",
																	}}
																>
																	{unescapeString(field)}
																	{personal && (
																		<SecurityIcon
																			style={{ fontSize: 18, marginLeft: 6 }}
																		/>
																	)}
																</div>
															</TableCell>
														);
													})}
												</TableRow>
											</TableHead>
											<TableBody>
												<>
													{this.state.registrations.map((row, i) => {
														return (
															<TableRow key={row.objectId}>
																<TableCell
																	padding="none"
																	style={{ paddingLeft: "8px" }}
																>
																	{i + 1}.
																</TableCell>
																{!this.props.doc.get("teamRegistration") ? (
																	<TableCell component="th" scope="row">
																		{row["user"]}
																	</TableCell>
																) : null}
																<>
																	{this.getAllRegFields(this.props.doc).map(
																		(field) => (
																			<TableCell
																				key={row.objectId + field[0]}
																				align="right"
																			>
																				{this.getFieldIfPossible(row, field[0])}
																			</TableCell>
																		)
																	)}
																</>
															</TableRow>
														);
													})}
												</>
											</TableBody>
										</Table>
									</div>{" "}
								</IfOnline>
							) : null}
						</div>
					</CardContent>
				</Collapse>
				<EetlijstComponent
					event={this.state.eetlijstEvent}
					close={this.closeEetlijstComponent}
					isCL={this.context.isCommissielid || this.context.isAdmin}
				/>
				<Snackbar
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "left",
					}}
					open={this.state.snackbarOpen}
					autoHideDuration={2000}
					onClose={this.handleSnackbarClose}
					ContentProps={{
						"aria-describedby": "message-id",
					}}
					message={
						<span id="message-id">Link gekopieerd naar het klembord</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							style={{ padding: "4px" }}
							onClick={this.handleSnackbarClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
			</Card>
		);
	}
}

EventCard.contextType = SettingsContext;

EventCard.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default EventCard;
