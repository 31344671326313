import React, { useState } from "react";

import { FormControl, TextField, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  paper: {
    border: "2px solid #9b9b9b",
    borderRadius: 25,
    padding: 25
  },
  inputField: {
    width: "100%"
  },
  dialogAction: {
    width: "100%"
  }
}));

const PollCreator = (props) => {
  const classes = useStyles(props);
  const [question, setQuestion] = useState(props.poll ? props.poll.get("question") || "" : "");
  const [answers, setAnswers] = useState(props.poll ? (props.poll.get("options") || []) : [""]);
  if (props.poll && !props.poll.isDataAvailable()) {
    props.poll.fetch().then((x) => {
      setQuestion(x.get("question"));
      setAnswers(x.get("options"));
    });
  }
  const pollChanged = (q, a)=> {
    if (props.pollChanged) {
      props.pollChanged(q, a);
    }
  }
  const handleUpdateQuestion = (event) => {
    setQuestion(event.target.value);
    pollChanged(event.target.value, answers);
  }
  const truncate = (list) => {
    while(list.length > 1 && list[list.length-1].trim() === ""){
      list.length -= 1;
    }
    list.push("");
    return list;
  }
  const handleUpdateAnswer = (i) => (event) => {
    let ans = answers.slice();
    ans[i] = event.target.value;
    truncate(ans);
    setAnswers(ans);
    pollChanged(question, ans);
  }
  return (
    <FormControl variant="standard" style={{width:"100%"}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField disabled={props.poll} error={props.questionError} className={classes.inputField} label="Vraag" value={question}
                     onChange={handleUpdateQuestion} variant="outlined" />
        </Grid>
        {
          answers.map((ans, i) => {
            return <Grid item xs={12} key={i}>
              <TextField disabled={props.poll} error={props.answerError} key={i} className={classes.inputField} label={"Antwoord " + (i+1)} value={answers[i]}
                         onChange={handleUpdateAnswer(i)} variant="outlined" />
            </Grid>
          })
        }
      </Grid>
    </FormControl>
  );
};

export default PollCreator;
