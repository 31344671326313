import React from "react";

import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";

import "./EventFilters.css";

const filterInschrijfstatus = (props, event) => {
	const now = new Date();
	const status = props.filterState.status;
	const deadline = event.registrationDeadline
		? new Date(event.registrationDeadline.iso)
		: undefined;
	const opening = event.registrationOpening
		? new Date(event.registrationOpening.iso)
		: undefined;
	if (status === "") {
		return true;
	} else if (status === "registered") {
		return props.isRegisteredFor(event.id);
	} else if (status === "has") {
		return event.openForRegistration;
	} else if (status === "open") {
		return (
			event.openForRegistration &&
			(!deadline || deadline > now) &&
			(!opening || opening < now)
		);
	} else {
		return false;
	}
};

const filterSearchbar = (props, event) => {
	const search = props.filterState.search;
	if (search === "") {
		return true;
	} else {
		return event.title.toLowerCase().match(search.toLowerCase());
	}
};

export const applyFilters = (props) => {
	return (event) => {
		return filterInschrijfstatus(props, event) && filterSearchbar(props, event);
	};
};

export const EventFilters = (props) => {
	const { status, search } = props.filterState;

	const setStatus = (e) => {
		props.handleFilterChange({ status: e.target.value, search: search });
	};

	const setSearch = (e) => {
		props.handleFilterChange({ status: status, search: e.target.value });
	};

	return (
		<div className="EventFilters">
			<div id="event-status-filter">
				<Typography variant="subtitle1">Filter</Typography>
				<FormControl
					variant="filled"
					style={{
						minWidth: 250,
						marginLeft: 15,
						maxWidth: "min(250px, 70vw)",
					}}
				>
					<InputLabel id="user-filter-label">Inschrijfstatus</InputLabel>
					<Select
						variant="filled"
						label="Inschrijfstatus"
						labelId="user-filter-label"
						value={status}
						onChange={setStatus}
					>
						<MenuItem value="">Alle</MenuItem>
						<MenuItem value="registered">Ingeschreven</MenuItem>
						<MenuItem value="has">Met inschrijving</MenuItem>
						<MenuItem value="open">Open voor inschrijving</MenuItem>
					</Select>
				</FormControl>
			</div>
			<div id="event-search-filter">
				<Typography variant="subtitle1">Zoeken</Typography>
				<TextField
					label="Activiteitnaam"
					type="search"
					variant="filled"
					value={search}
					onChange={setSearch}
				/>
			</div>
		</div>
	);
};
