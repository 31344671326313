import React, { useState } from "react";
import Parse from "parse";

import { FormControl, RadioGroup, FormControlLabel, Radio, Button, Typography, LinearProgress, Grid } from "@mui/material";
import { withStyles } from "@mui/styles";

const Progress = withStyles((theme) => ({
  root: {
    height: "1em",
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 0,
    backgroundColor: 'primary',
  },
}))(LinearProgress);

const Poll = (props) => {
  const [currentId, setCurrentId] = useState("");
  const [selected, setSelected] = useState(-1);
  const [loaded, setLoaded] = useState(false);
  const title = (loaded && props.poll && props.poll.get("question")) || "Vraag laden...";
  const options = props.poll ? props.poll.get("options") || [] : [];
  const pollObject = props.poll && props.poll.isDataAvailable() && props.poll;
  const [canVote, setCanVote] = useState(false);
  const [showResult, setShowResult] = useState(pollObject && !canVote);
  const refresh = useState(null)[1];
  if (props.poll && !props.poll.isDataAvailable()) {
    props.poll.fetch().then(() => {
      refresh();
    });
  }
  if (pollObject && currentId !== props.poll.id) {
    setCurrentId(pollObject.id);
    const voteQuery = new Parse.Query("PollVote");
    voteQuery.equalTo("poll", pollObject.id);
    voteQuery.equalTo("voter", Parse.User.current().id);
    voteQuery.find().then((results) => {
      if (results.length > 0) {
        setCanVote(false);
        setShowResult(true);
      } else {
        setCanVote(true);
        setShowResult(false);
      }
      setLoaded(true);
    });
  }
  const handleChange = (event) => {
    setSelected(parseInt(event.target.value));
  };
  const handleSubmit = () => {
    if (selected >= 0 && pollObject) {
      const PollVote = Parse.Object.extend("PollVote");
      let vote = new PollVote();
      vote.set("voter", Parse.User.current().id);
      vote.set("poll", pollObject.id);
      vote.set("voteValue", selected);
      let acl = new Parse.ACL();
      acl.setRoleReadAccess("Lid", true);
      vote.setACL(acl);

      vote.save().then(() => {
        setCanVote(false);
        pollObject.fetch().then(() => {
          //make sure the pollObject in the state is updated, and then force a rerender by setShowResult(true)
          setShowResult(true);
        })
      }).catch(e => {
        //maybe you already voted?
        console.log(e)
        setCanVote(false);
        setShowResult(true);
      })
    }
  }
  const handleToResult = () => {
    setShowResult(true);
  }
  const handleToVotes = () => {
    setShowResult(false);
  }
  const voteCount = pollObject ? pollObject.get("voteCount") : "?";
  if (!loaded) {
    return (
      <FormControl variant="standard" style={{ width: "100%" }}>
        <Typography variant="h6">{title}</Typography>
      </FormControl>
    );
  }
  if (showResult) {
    return (
      <FormControl variant="standard" style={{ width: "100%" }}>
        <Typography variant="h6">{title}</Typography>
        <Grid container spacing={1}>
          {options.map((v, i) => {
            if (pollObject) {
              const total = pollObject.get("voteCount");
              const thisAnswer = pollObject.get("voteCount" + i);
              const percentage = total === 0 ? 0 : Math.round(100 * thisAnswer / total);
              return <Grid key={i} item container alignItems="center" spacing={1}>
                <Grid item xs={4}><Typography variant="body1">{v}</Typography></Grid>
                <Grid item xs={2}><Typography variant="body2">{percentage}%    </Typography>  </Grid>
                <Grid item xs={6}><Progress variant="determinate" value={percentage} /></Grid>
              </Grid>
            } else {
              return null
            }
          })}
        </Grid>
        <Typography variant="body2">{voteCount} {voteCount === 1 ? "stem" : "stemmen"}</Typography>
        {canVote ? (
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" onClick={handleToVotes}>Terug</Button>
              </Grid>
            </Grid>
        ) : null}
      </FormControl>
    );
  } else {
    return (
      <FormControl variant="standard" style={{ width: "100%" }}>
        <Typography variant="h6">{title}</Typography>
        <RadioGroup value={selected} onChange={handleChange}>
          {options.map((v, i) => {
            return <FormControlLabel key={i} value={i} control={<Radio />} label={v} />
          })}
        </RadioGroup>
        {pollObject ?
            <Grid container spacing={1}>
              <Grid item>
                <Button variant="contained" onClick={handleToResult}>Resultaten</Button>
              </Grid>
              {canVote ?
                  <Grid item>
                    <Button variant="contained" color="primary" disabled={selected === -1} onClick={handleSubmit}>Stem!</Button>
                  </Grid>
                  : null}
            </Grid>
            : null}
      </FormControl>
    );
  }
};

export default Poll;
