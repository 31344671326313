import React, { useState } from "react";
import Parse from "parse";

import {
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
	Snackbar,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import WindowTitleComponent from "./WindowTitleComponent";

function GehoordComponent(props) {
	const [gehoordMessage, setGehoordMessage] = useState("");
	const [snackMessage, setSnackMessage] = useState(null);
	const [sendMessage, setSendMessage] = useState(false);

	const handleGehoord = async (gehoordMessage) => {
		if (!gehoordMessage || gehoordMessage.length < 1) return;
		try {
			setSendMessage(true);
			let data = await Parse.Cloud.run("sendGehoord", {
				gehoordMessage: gehoordMessage,
			});
			if (data.status === "ok") {
				setSnackMessage("Je gehoord is succesvol doorgegeven aan Bartjens!");
				setGehoordMessage("");
			}
			if (data.status === "error") {
				setSnackMessage(
					"Oei, oei, oei... Er is iets fout gegaan bij het verzenden van de gehoord: " +
						data.error
				);
			}
		} catch (error) {
			setSnackMessage(
				"Oei, oei, oei... Er is iets fout gegaan bij het verzenden van de gehoord: " +
					error
			);
		} finally {
			setSendMessage(false);
		}
	};

	return (
		<>
			<WindowTitleComponent title="Gehoord" />
			<Card className="paper-card login-card">
				<form>
					<CardContent className="inlog-card">
						<div className="inlog-card">
							<Typography gutterBottom variant="h5" component="h1">
								Gehoord
							</Typography>
							<Typography component="p">
								Heeft een Desdaan iets grappigs gezegd? Stuur het dan hier in,
								misschien verschijnt het in de <em>Volgens Bartjens</em>!
							</Typography>
							<TextField
								id="bug"
								label="Gehoord"
								value={gehoordMessage}
								onChange={(event) => setGehoordMessage(event.target.value)}
								variant="outlined"
								margin="normal"
								multiline
								minRows="4"
							/>

							<Button
								disabled={sendMessage}
								className="login-button"
								variant="contained"
								color="primary"
								onClick={() => handleGehoord(gehoordMessage)}
							>
								Insturen
							</Button>
						</div>
					</CardContent>
				</form>
			</Card>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackMessage != null}
				autoHideDuration={2000}
				onClose={() => {
					setSnackMessage(null);
				}}
				ContentProps={{
					"aria-describedby": "message-id",
				}}
				message={snackMessage}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						style={{ padding: "4px" }}
						onClick={() => {
							setSnackMessage(null);
						}}
						size="large"
					>
						<CloseIcon />
					</IconButton>,
				]}
			/>
		</>
	);
}

export default GehoordComponent;
