export const getAllRegFields = (event, includePersonal) => {
    let safe = (list) => list?list:[];
    if (!event.get("teamRegistration")) {
        let nonPersonal = safe(event.get("nonPersonalRegFields"));
        let result = [];
        for (let field of safe(event.get("extraRegFields"))) {
            if (nonPersonal.indexOf(field) === -1) {
                if (includePersonal) {
                    result.push([field, true]);
                }
            } else {
                result.push([field, false]);
            }
        }
        return result;
    } else {
        let fields = safe(event.get("nonPersonalRegFields")).map(x => [x, false]);
        if (includePersonal) {
            fields = fields.concat(safe(event.get("personalTeamRegFields")).map(x => [x, true]));
        }
        let personal = safe(event.get("extraRegFields"))
        let nonPersonal = safe(event.get("perPersonRegFields"))
        for (let i = 0; i < event.get("maxTeamSize"); i++) {
            fields.push(["Teamlid " + (i + 1), false])
            fields = fields.concat(nonPersonal.map((str) => [("Teamlid " + (i + 1) + ": " + str), false]));
            if (includePersonal) {
                fields = fields.concat(personal.map((str) => [("Teamlid " + (i + 1) + ": " + str), true]));
            }
        }
        return fields;
    }
}
