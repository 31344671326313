import React from "react";
import { Redirect } from "react-router-dom";
import Parse from "parse";

import {
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
	Snackbar,
	IconButton, Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Config from "./config";

class LoginComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			isLoggingIn: false,
			snackbarOpen: false,
		};
	}

	handleChange = (name) => (event) => {
		this.setState({
			[name]: event.target.value,
		});
	};

	handleSnackbarClose = () => {
		this.setState({ snackbarOpen: false });
	};

	handleKeyPress = (event) => {
		if (event.key === "Enter") {
			this.handleLogin();
		}
	};

	handleLogin = () => {
		this.setState({ isLoggingIn: true });
		if (Config.useLocalAuth) {
			(async () => {
				await Parse.User.logIn(this.state.username, this.state.password);
				this.setState({ isLoggingIn: false });
				const url_string = window.location.href;
				const url = new URL(url_string);
				const requestedPage = url.searchParams.get("req");
				this.setState({ isLoggingIn: false });
				this.props.onLoggedIn(null, requestedPage);
			})();
			return;
		}

		var data = new FormData();
		data.append("username", this.state.username);
		data.append("password", this.state.password);

		var that = this;
		fetch(Config.authURL, {
			method: "post",
			body: data,
		})
			.then(function (response) {
				console.log(response);
				that.setState({ isLoggingIn: false });
				if (response.status >= 200 && response.status < 300) {
					return response.text();
				}
				throw new Error(response.statusText);
			})
			.then(function (response) {
				console.log(response.trim());
				if (response.trim() === "ERROR: Invalid username or password") {
					that.setState({
						snackbarOpen: true,
						isLoggingIn: false,
					});
				} else {
					var url_string = window.location.href;
					var url = new URL(url_string);
					var requestedPage = url.searchParams.get("req");
					that.setState({ isLoggingIn: false });
					that.props.onLoggedIn(response, requestedPage);
				}
			});
	};

	handleBug = () => {
		if (!this.state.bugMessage || this.state.bugMessage.length < 1) return;
		const Bug = this.props.parse.Object.extend("Bug");
		let bug = new Bug();
		bug.set("message", this.state.bugMessage);
		bug.set("user", this.props.user);
		bug.save().then(
			(event) => {
				// Execute any logic that should take place after the object is saved.
			},
			(error) => {
				// Execute any logic that should take place if the save fails.
				// error is a Parse.Error with an error code and message.
				alert("Failed to create new object, with error code: " + error.message);
			}
		);

		this.setState({ bugMessage: "" });
	};

	goToApp() {
		window.location.replace("/");
	}

	render() {
		return (
			<div>
				{!this.props.loggedIn ? (
					<Card className="paper-card login-card">
						<form>
							<CardContent className="inlog-card">
								<div className="inlog-card">
									<Typography gutterBottom variant="h5" component="h1">
										Inloggen
									</Typography>
									<Typography component="p">
										Log hieronder in met je gebruikersnaam en wachtwoord van de
										Desda-site.
									</Typography>
									<TextField
										id="username"
										label="Gebruikersnaam"
										value={this.state.username}
										onChange={this.handleChange("username")}
										variant="outlined"
										margin="normal"
										autoComplete="username"
									/>
									<TextField
										id="password"
										label="Wachtwoord"
										value={this.state.password}
										onChange={this.handleChange("password")}
										type="password"
										variant="outlined"
										autoComplete="current-password"
										margin="normal"
										onKeyPress={this.handleKeyPress}
									/>
									<Button
										className="login-button"
										variant="contained"
										color="primary"
										onClick={this.handleLogin}
										disabled={this.state.isLoggingIn}
									>
										Inloggen
									</Button>
									<Snackbar
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "left",
										}}
										open={this.state.snackbarOpen}
										autoHideDuration={6000}
										onClose={this.handleSnackbarClose}
										ContentProps={{
											"aria-describedby": "message-id",
										}}
									>
										<Alert severity="error">Gebruikersnaam of wachtwoord incorrect.
											<IconButton
											key="close"
											aria-label="Close"
											color="inherit"
											style={{ padding: "4px" }}
											onClick={this.handleSnackbarClose}
											size="large"
											>
												<CloseIcon />
											</IconButton>
										</Alert>
									</Snackbar>
								</div>
							</CardContent>
						</form>
					</Card>
				) : (
					<Redirect to="/settings" />
				)}{" "}
			</div>
		);
	}
}

export default LoginComponent;
