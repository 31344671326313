import React, { useState } from "react";
import { Detector } from "react-detect-offline";


export const OnlineContext = React.createContext();

const OnlineProvider = (props) => {
  const [online, setOnline] = useState(true);

  return (
      <OnlineContext.Provider value={online}>
        <Detector
            polling={{
              url: "https://desda.org/online.php",
              interval: 10000
            }}
            render={({ online }) => {
              setOnline(online);
              return null;
            }}/>
        {props.children}
      </OnlineContext.Provider>
  )
}

export default OnlineProvider;

export const OnlineDetector = (props) => {
  return (
      <OnlineContext.Consumer>
        {online => props.render(online)}
      </OnlineContext.Consumer>
  )
}

export const IfOnline = (props) => {
  return (
      <OnlineContext.Consumer>
        {online => {
          if(online) {
            return props.children;
          } else {
            return null;
          }
        }}
      </OnlineContext.Consumer>
  )
}

export const IfOffline = (props) => {
  return (
      <OnlineContext.Consumer>
        {online => {
          if(!online) {
            return props.children;
          } else {
            return null;
          }
        }}
      </OnlineContext.Consumer>
  )
}