import React from "react";
import YouTube from "react-youtube";
import MarkDownIt from "markdown-it";


// import DOMPurify from "dompurify";
const md = new MarkDownIt({linkify: true, breaks: true});
export function YouTubeGetID(url) {
  //Source: https://gist.github.com/takien/4077195
  var ID = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    // eslint-disable-next-line
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  // else {
  //   ID = url;
  // }
  return ID;
}

export function youtubify(text, opts={height:'180', width:'100%'}, container=null, classname=null) {
  // eslint-disable-next-line
  var urlRegex = /(\b(https?):\/\/[-A-Z0-9+&@#\*\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  let youtube;
  let ytid = YouTubeGetID;
  let textout = text.replace(urlRegex, function(url, g1, g2, offset) {
    let youtube_id = ytid(url);
    if (youtube_id !== '') {
      youtube = <YouTube videoId={youtube_id} className={classname} opts={opts} containerClassName={container}/>;
      return '';
    }
    return url;
    // //check if not part of markdown already!
    // else if(offset > 2 && text.length > offset + url.length && text[offset -1 ] === "(" && text[offset -2 ] === "]" && text[offset + url.length] === ")" )
    //     return url;
    // else if (url.match(/\.(jpeg|jpg|gif|png)$/) != null){

    //     return '<img src="' + url +'" style="max-height:300px;max-width:100%">';
    // }
    // else {
    //   return '<a href="' + url + '">' + url + "</a>";
    // }
  });
  return [textout, youtube];
}

export function linkifyMarkdifySanitize(text, opts={height:'', width:''}, container=null, classname=null) {
  // let renderer = new marked.Renderer();
  // renderer.image = function (href, title, text) {
  //       return '<img src="' + href +'" alt="'+text+ '" style="max-height:300px;max-width:100%">';
  //   };
  // text = text.replace(/(?:\r\n|\r|\n)/g, "  \n"); //make enters behave more understandable for normal people
  let [textout, youtube] = youtubify(text, opts, container, classname);
  let htmlout = md.render(textout); //markdown-it is safe and doesn't accept html
  return [htmlout,youtube];
}

export default linkifyMarkdifySanitize;
