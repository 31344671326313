import Parse from "parse";

const dateFormat = new Intl.DateTimeFormat("nl-NL", {
	dateStyle: "long",
	timeStyle: "short",
});

const FONT = "helvetica";

const tableOptions = {
	headStyles: { fillColor: 255, textColor: 0 },
	footStyles: {
		fillColor: 200,
		textColor: 0,
	},
	alternateRowStyles: { fillColor: 250 },
	styles: { cellPadding: 2.5, textColor: 15 },
	rowPageBreak: "avoid",
	showFoot: "lastPage",
	theme: "grid",
	startY: 33,
};

function number_format(n) {
	return (n.toFixed(2) + "").replace(".", ",");
}

async function queryEetlijstBestelling(event) {
	if (Parse.User.current()) {
		const query = new Parse.Query("EetlijstBestelling");
		query.equalTo("eventId", event.id);
		const results = await query.findAll({});
		let jsonresults = [];
		for (let res of results) {
			jsonresults.push(res.toJSON());
		}
		return jsonresults;
	}
}

async function makeBase(pdf) {
	if (!pdf) {
		const { jsPDF } = await import("jspdf");
		const { applyPlugin } = await import("jspdf-autotable");

		applyPlugin(jsPDF);

		pdf = new jsPDF({
			orientation: "p",
			unit: "mm",
			format: "a4",
			putOnlyUsedFonts: true,
			floatPrecision: 16,
		});
	} else {
		pdf.addPage();
	}

	// Setup some formatting stuff.
	pdf.setFontSize(12);
	pdf.setFont(FONT, "normal");

	return pdf;
}

function makeTitle(pdf, title) {
	pdf.setFont(FONT, "bold");
	pdf.setFontSize(16);
	pdf.text(title, 20, 20);
	pdf.setFont(FONT, "normal");
	pdf.setFontSize(12);

	pdf.text(dateFormat.format(Date.now()), 20, 26);

	return pdf;
}

function makeContent(pdf, eetlijst, menu) {
	let i = 0;

	eetlijst.sort((a, b) => {
		if (a.displayName < b.displayName) {
			return -1;
		} else if (a.displayName > b.displayName) {
			return 1;
		} else {
			return 0;
		}
	});

	let pricePerPerson = [];
	while (i < eetlijst.length) {
		let totaal = eetlijst[i].price;
		while (
			i + 1 < eetlijst.length &&
			eetlijst[i].displayName === eetlijst[i + 1].displayName
		) {
			i++;
			totaal += eetlijst[i].price;
		}
		pricePerPerson.push([
			eetlijst[i].displayName,
			`\u20AC ${number_format(totaal)}`,
			"",
		]);
		i++;
	}

	eetlijst.sort((a, b) => {
		if (a.food < b.food) {
			return -1;
		} else if (a.food > b.food) {
			return 1;
		} else {
			return 0;
		}
	});

	let foodandprice = [];
	menu.forEach((item) => {
		foodandprice.push([item.food, item.price, 0, 0]);
	});

	eetlijst.forEach((res) => {
		let found = false;
		for (let i = 0; i < foodandprice.length; i++) {
			if (res.food == foodandprice[i][0]) {
				found = true;
				if (!foodandprice[i][2]) {
					foodandprice[i][2] = 1;
				} else {
					foodandprice[i][2]++;
				}
				foodandprice[i][3] = foodandprice[i][1] * foodandprice[i][2];
			}
		}
		if (!found) {
			foodandprice.push([res.food, res.price, 1, res.price]);
		}
	});

	let totaantal = 0;
	let totprijs = 0;
	foodandprice.forEach((fp) => {
		if (fp[3]) {
			totaantal += fp[2];
			totprijs += fp[3];
		}
	});

	pdf = makeTitle(pdf, "Eetlijst");

	pdf.autoTable({
		head: [["Naam", "Totaalbedrag", "Betaald"]],
		body: pricePerPerson,
		...tableOptions,
	});

	pdf.addPage();
	pdf = makeTitle(pdf, "Eetlijst");

	pdf.autoTable({
		head: [["Eten", "Naam", "Opgehaald"]],
		body: eetlijst.map((res) => [res.food, res.displayName, ""]),
		...tableOptions,
	});

	pdf.addPage();
	pdf = makeTitle(pdf, "Bestellijst");

	pdf.autoTable({
		head: [["Eten", "Aantal", "Stukprijs", "Totaalprijs"]],
		body: foodandprice
			.filter((fp) => fp[2] > 0)
			.map((fp) => [
				fp[0],
				fp[2],
				`\u20AC ${number_format(fp[1])}`,
				`\u20AC ${number_format(fp[3])}`,
			]),
		foot: [["Totaal", totaantal, "-", `\u20AC ${number_format(totprijs)}`]],
		...tableOptions,
	});

	return pdf;
}

export default function printEetlijst(event, eetlijstMenu) {
	return new Promise((compileSuccess, compileError) => {
		queryEetlijstBestelling(event).then(async (eetlijst) => {
			try {
				let pdf = await makeBase();
				pdf = makeContent(pdf, eetlijst, eetlijstMenu);
				const pdfblob = new Blob([pdf.output("blob")], {
					type: "application/pdf",
				});
				const objectURL = URL.createObjectURL(pdfblob);
				setTimeout(() => {
					URL.revokeObjectURL(objectURL);
				}, 10000);
				const popUp = window.open(objectURL, "eetlijstpdf");
				if (popUp == null || typeof popUp == "undefined") {
					compileError(
						`Popup openen mislukt. Sta ze toe en probeer opnieuw, of klik hieronder op "Open PDF" om hem alsnog te openen. : ${objectURL}`
					);
				} else {
					compileSuccess();
				}
			} catch (e) {
				console.error(e);
				compileError("Er is iets misgegaan tijdens het genereren van de PDF.");
			}
		});
	});
}
