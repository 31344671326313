import React, { useState } from "react";

import { Select, FormControl, InputLabel } from "@mui/material"

import formatDate from "../FormatDate";


const EventPicker = (props) => {
  const GEENACTIVITEIT =  "GEENACTIVITEIT";
  let selectedId = GEENACTIVITEIT;
  if(props.event)
  {
    selectedId = props.event.objectId;

  }
  const [selected, setSelectedState] = useState(selectedId);
  const setSelected = (eventId) => {
    setSelectedState(eventId);
    if (props.setSelected) {
      if(eventId === GEENACTIVITEIT )
        props.setSelected(null);
      else
        props.setSelected(eventId);
    }
  }
  const activities = (old) => {
    const result = props.events.map((event) => {
      if ((old && event.startDate >= new Date()) || (!old && event.startDate < new Date())) {
        return null;
      }
      return <option key={event.id} value={event.id}>{event.title} ({formatDate(event.startDate, false)})</option>

    });
    if (old) {
      result.reverse();
    }
    return result;
  };
  return (
    <FormControl variant="standard" style={{width:"100%"}}>
      <InputLabel htmlFor="evetn-picker-select" variant="outlined">Activiteit</InputLabel>
      <Select native label="Activiteit" id="event-picker-select" variant="outlined" placeholder="Kies hier je eten" value={selected}
              onChange={(event) => {if (event.target) {
                console.log(event.target.value)
                setSelected(event.target.value);
              } else {
                setSelected(GEENACTIVITEIT);
              }}}>
        <option value={GEENACTIVITEIT}>Geen activiteit</option>
        {activities(false)}
        <option disabled style={{fontWeight:"bold", color:"black"}}>---Oude activiteiten---</option>
        {activities(true)}
      </Select>
    </FormControl>
  );
};

export default EventPicker;