import React, { useState, useContext } from "react";
import Parse from "parse";
import randomColor from "randomcolor";

import {CardContent, CardHeader, IconButton, TextField, Button, Typography, Avatar} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";

import { formatDate, relativeDate } from "../FormatDate";
import AreYouSure from "../AreYouSureComponent";
import { SettingsContext } from "../Settings";
import { linkifyMarkdifySanitize } from "../YouTube";


const CommentContent = (props) => {
  let c = props.c;
  let comment = props.comment;

  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState(c.content);
  const [ays, setAYS] = useState(false);

  const saveCommentEdit = (e) => {

    if (message.length < 2) {
      //"Message should have actual content"
      return false;
    }

    if (message === c.content) {
      //Message didn't change
      setEditing(false);
      return;
    }

    let newcomment = comment;
    newcomment.set("content", message);
    newcomment.save().then(
        () => {
          setEditing(false);
        },
        error => {
          console.log(error);
        }
    );
  }

  const deleteThisComment = () => {
    comment.destroy();
    setAYS(false);
  }

  const [sanitizedContent, youtube] = linkifyMarkdifySanitize(c.content)

  const settings = useContext(SettingsContext);

  let dateStr;
  if(Date.now() - comment.updatedAt > (60 * 60 * 1000)) {
    dateStr = formatDate(comment.updatedAt, true, true, new Date());
  } else {
    dateStr = relativeDate(comment.updatedAt);
  }

  return <>
    <CardHeader
        avatar={
          <Avatar style={{
            backgroundColor: randomColor({ luminosity: "dark", seed: c.authorName }),
            height: 35,
            width: 35
          }}>
            {c.authorName.split(' ').map(s => { return s[0] }).join('')}
          </Avatar>
        }
        title={c.authorName}
        subheader={(c.updatedAt === c.createdAt ? "Geplaatst: " : "Laatst bewerkt: ") + dateStr}
        titleTypographyProps={{ variant: 'subtitle2', component: 'h2' }}
        style={{
          padding: "16px 16px 10px 16px"
        }}
        action={
          (function () {
            if (settings.isAdmin || Parse.User.current().id === c.authorId) {
              return <>
                <AreYouSure open={ays} yes={deleteThisComment} no={() => setAYS(false)} text="Weet je zeker dat je deze comment wilt verwijderen?" />
                <IconButton onClick={e => setAYS(true)} size="large">
                  <DeleteIcon />
                </IconButton>
                {Parse.User.current().id === c.authorId ?
                    <IconButton onClick={e => setEditing(true)} size="large">
                      <CreateIcon />
                    </IconButton>
                    : null}
              </>;
            }
          })()

        }
    />
    <CardContent style={{ padding: "10px 16px" }}>
      {editing ?
          (<>
            <TextField
              variant="standard"
              fullWidth={true}
              multiline={true}
              value={message}
              onChange={e => { setMessage(e.target.value) }}></TextField>
            <Button onClick={saveCommentEdit} color="primary">OPSLAAN</Button>
          </>)
          :
          (
              <>
                <Typography
                    variant="body2"
                    component="p"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedContent
                    }}
                />
                {youtube}
              </>
          )
      }
    </CardContent>
  </>;
}

export default CommentContent;
