import Parse from "parse";


export function checkCanReceivePush() {
  return 'PushManager' in window && 'serviceWorker' in navigator;
}

export async function updateSW() {
  if(! navigator.serviceWorker)
    return;
  let registrations = await navigator.serviceWorker.getRegistrations();
  if (registrations.length === 0)
    return;
  let registration = registrations[0];
  registration.update();
}

export async function getPushSubscriptions(subscribeIfNotExists = false) {
  if(! navigator.serviceWorker) return null;
  let registrations = await navigator.serviceWorker.getRegistrations();
  if (registrations.length === 0) return null;
  let registration = registrations[0];
  const subscribeOptions = {
    userVisibleOnly: true,
    applicationServerKey: base64UrlToUint8Array(
        'BNotb3Pabe7lQGFirkgwfr0AWBTPQHKfHzW4zW1lvbgtVb1j-xltxapEeaf1-AaI7gGdEw2cMuvJt_k6PP4KvRM'
    )
  };
  let pushSubscription = await registration.pushManager.getSubscription();
  if (!pushSubscription && subscribeIfNotExists) { //go get a subscription
    return registration.pushManager.subscribe(subscribeOptions);
  }
  return pushSubscription;

}
//get the unique pushsubscription related to this device and this user
export async function getPushSubscriptionsFromParse(uid) {
  let pushSubscription = await getPushSubscriptions();
  if (pushSubscription) {
    const query = new Parse.Query("PushSubscription");
    query.equalTo("uid", uid);
    query.equalTo("pushSubscription", JSON.stringify(pushSubscription));
    return await query.first();
  }
  return null


}

function base64UrlToUint8Array(base64UrlData) {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  // eslint-disable-next-line
  const base64 = (base64UrlData + padding).replace(/\-/g, '+').replace(/_/g, '/');
  const rawData = atob(base64);
  const buffer = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    buffer[i] = rawData.charCodeAt(i);
  }
  return buffer;
}

export function askPermissionForPush() {
  return new Promise(function(resolve, reject) {
    const permissionResult = Notification.requestPermission(function(result) {
      resolve(result);
    });

    if (permissionResult) {
      permissionResult.then(resolve, reject);
    }
  })
}

export function savePushSubscriptionInParse(currentParsePushSubscription, pushSubscription, switchChange, user) {
  let sub;
  if(currentParsePushSubscription)
  {
    sub = currentParsePushSubscription;
  }
  else
  {
    const PushSubscription = Parse.Object.extend("PushSubscription");
    sub = new PushSubscription();
    sub.set("OnNewEvent",false)
    sub.set("OnNewPost",false)
    sub.set("OnNewCommentParentIds",[]);
  }
  if(! sub.has("OnNewCommentParentIds"))
  {
    sub.set("OnNewCommentParentIds",[]);
  }

  sub.set("pushSubscription", JSON.stringify(pushSubscription));
  sub.set("user", user);
  sub.set("uid", user.id);

  if (switchChange.hasOwnProperty("pushMessagesForEvents")) {
    sub.set("OnNewEvent", switchChange.pushMessagesForEvents);
  }

  if(switchChange.hasOwnProperty("pushMessagesForPosts"))
  {
    sub.set("OnNewPost",switchChange.pushMessagesForPosts);
  }

  if(switchChange.hasOwnProperty("togglePushMessagesForCommentParentId"))
  {
    if(! sub.get("OnNewCommentParentIds").includes(switchChange.togglePushMessagesForCommentParentId))
    {
      sub.addUnique("OnNewCommentParentIds", switchChange.togglePushMessagesForCommentParentId);
    }
    else
    {
      sub.remove("OnNewCommentParentIds",switchChange.togglePushMessagesForCommentParentId );
    }
  }
  sub.setACL(new Parse.ACL(user), {});
  return sub.save()
}
