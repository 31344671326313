import React from "react";
import { readAndCompressImage } from "browser-image-resizer";
import FileInputComponent from "react-file-input-previews-base64";
import Parse from "parse";

import { Button, Grid, IconButton } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { rotateImage, blobToBase64 } from "./RotateImage";
import RotateLeft from "@mui/icons-material/RotateLeft";
import RotateRight from "@mui/icons-material/RotateRight";
import { useState } from "react";

export const saveImg = async (prefix, img) => {
	let imgStringRef = prefix + new Date().getTime() + ".jpg";
	const parseFile = new Parse.File(imgStringRef, new File([img], imgStringRef));
	const result = await parseFile.save();
	return result;
};

const ImageInputComponent = (props) => {
	const [url, setUrl] = useState(props.img != null ? props.img.url : "");
	const updateImage = (img) => {
		setUrl(img.url);
		if (props.onImgChange) {
			props.onImgChange(img.blob);
		}
	};
	const config = {
		quality: 0.7,
		maxWidth: 800,
		maxHeight: 600,
		autoRotate: true,
		debug: true,
	};
	// const [uploadingImage, setUploadingImage] = useState(false);
	const uploadImage = async (picture) => {
		const resizedImage = await readAndCompressImage(picture.file, config);
		updateImage({ blob: resizedImage, url: await blobToBase64(resizedImage) });
	};
	const rotate = (direction) => {
		return async () => {
			const rotatedImage = await rotateImage(url, direction);
			updateImage(rotatedImage);
		};
	};
	return (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<img src={url}></img>
			</Grid>
			<Grid item>
				<FileInputComponent
					labelStyle={{ display: "none" }}
					multiple={false}
					callbackFunction={uploadImage}
					accept="image/*"
					imagePreview={false}
					imageStyle={{
						marginTop: 5,
						marginBottom: 5,
						marginRight: 5,
						height: "auto",
						maxWidth: "100%",
						maxHeight: "25rem",
						width: "auto",
						boxShadow:
							"rgba(0, 0, 0, 0.188235) 0px 10px 30px, rgba(0, 0, 0, 0.227451) 0px 6px 10px",
						margin: "0 auto",
					}}
					buttonComponent={
						<Button
							variant="outlined"
							color="primary"
							className="upload-button"
						>
							<AddAPhotoIcon className="image-icon" />
							Afbeelding toevoegen
						</Button>
					}
					defaultFiles={props.img != null ? [props.img.url] : []}
				/>
			</Grid>
			<Grid
				item
				container
				direction="row"
				xs={12}
				alignItems="center"
				justifyContent="center"
			>
				<Grid item>
					<IconButton onClick={rotate(-1)}>
						<RotateLeft />
					</IconButton>
				</Grid>
				<Grid item>
					<IconButton onClick={rotate(1)}>
						<RotateRight />
					</IconButton>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ImageInputComponent;
