import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  const { icon, primary, to, referer } = props;

  const renderLink = React.useMemo(
      () => React.forwardRef((itemProps, ref) =>
          /^https?:\/\//.test(to) //https://github.com/ReactTraining/react-router/issues/1147#issuecomment-32411131
              // eslint-disable-next-line
              ? <a target="_blank" rel="noopener" referrerPolicy={referer ? "no-referrer-when-downgrade" : "no-referrer"} href={to} {...itemProps} />
              : <RouterLink to={to} ref={ref} {...itemProps} />),
      [to, referer],
  );

  return (
      <li>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  referer: PropTypes.bool,
};

function SubmenuComponent(props) {
  const classes = useStyles();

  let menuItems = props.menu.map(item => {
    if (item.onlyOnline && !props.online)
      return null;

    return  <ListItemLink to={item.to} icon={item.icon} primary={item.label} referer={item.referer} key={item.label} />
  })
  return (
      <div className={classes.root}>
        <List component="nav">
          {menuItems}
        </List>
      </div>
  );
}

export default SubmenuComponent;
