import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Link } from "react-router-dom";

import { Badge, Button } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";

const CommentsPreview = (props) => {

  const [comments, setComments] = useState([]);

  useEffect(() => {

    //Parse code om de comments op te halen.
    let query = new Parse.Query("Comment");
    query.equalTo("parentType", "Post");
    query.equalTo("parentId", props.parentId);

    query.findAll({}).then(setComments);

  }, [props.parentId]);

  return (
      <Badge badgeContent={comments.length} overlap="rectangular" color="primary">
        <Button
            size="small"
            aria-label="Reacties"
            color="primary"
            component={Link}
            to={`/de37/${props.parentId}`}
        >
          <ForumIcon style={{ marginRight: "8px" }}/>
          Reacties
        </Button>
      </Badge>
  )
}

export default CommentsPreview;