export function blobToBase64(blob) {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
    });
}

export function rotateImage(url, direction) {
    return new Promise((resolve) => {
        const img = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.src = url;
        img.onload = () => {
            console.log({w:img.width, h:img.height});
            const canvas = document.createElement("canvas");
            canvas.width = img.height;
            canvas.height = img.width;
            const ctx = canvas.getContext("2d");
            ctx.save();
            ctx.fillStyle = '#ffffff';
            ctx.fillRect(0, 0, canvas.width, canvas.height);
            const degToRad = x => (Math.PI / 180) * x;
            ctx.save();
            ctx.rotate(degToRad(direction * 90));
            if (direction > 0) {
                ctx.drawImage(img, 0, -canvas.width);
            } else if (direction < 0) {
                ctx.drawImage(img, -canvas.height, 0);
            } else {
                ctx.drawImage(img, 0, 0);
            }
            ctx.restore();
            canvas.toBlob((blob => {
                blobToBase64(blob).then(url => {
                    resolve({blob, url});
                })
            }), "image/jpeg");
        };
    });
}
