import React from "react";
import {marked} from "marked";

import { Card, CardContent, Typography, Button, Snackbar, IconButton, CardHeader, FormControl, FormGroup,
  FormControlLabel, Switch, Select, InputLabel } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IfOnline } from "./OnlineContext";
import * as utils from "./DataUtils";
import changelog from "./changelog.md";
import { SettingsContext } from "./Settings";
import WindowTitleComponent from "./WindowTitleComponent";


class SettingsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      markdownHtml: "",
      bugMessage: "",
      parsePushSubscription: null,
      snackMessage: null
    };
  }

  componentDidMount() {

    fetch(changelog)
        .then(response => {
          return response.text();
        })
        .then(text => {
          this.setState({
            markdownHtml: marked(text)
          });
        });
    let that = this;
    utils.getPushSubscriptionsFromParse(this.props.user.id).then(subscription => {
      that.setState({ parsePushSubscription: subscription });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSwitchChange = event => {
    this.handlePushSubscriptionChange({ [event.target.name]: event.target.checked });
  };

  handlePushSubscriptionChange = switchChange => {
    let that = this;
    if (!utils.checkCanReceivePush()) {
      return null;
    }
    utils
        .askPermissionForPush()
        .then(function (permissionResult) {
          if (permissionResult !== "granted") {
            that.setState({
              snackMessage:
                  "Er is iets fout gegaan bij het geven van toestemming voor pushmeldingen. Probeer het opnieuw."
            });
            return null;
          } else {
            return that.subscribeUserToPush();
          }
        })
        .then(subscription => {
          if (subscription) {
            this.savePushSubscriptionInParse(subscription, switchChange);
          }
        });
  };

  subscribeUserToPush = () => {
    let that = this;
    return utils.getPushSubscriptions(true).then(function (pushSubscription) {
      if (pushSubscription == null) {
        that.setState({
          snackMessage: "Er is iets fout gegaan bij het instellen van pushmeldingen. Probeer het later opnieuw."
        });
      }
      return pushSubscription;
    });
  };

  savePushSubscriptionInParse = (pushSubscription, switchChange) => {
    let that = this;
    utils
        .savePushSubscriptionInParse(this.state.parsePushSubscription, pushSubscription, switchChange, this.props.user)
        .then(
            subscription => {
              that.setState({ parsePushSubscription: subscription });
              that.setState({ snackMessage: "Je pushinstelling is aangepast!" });
            },
            error => {
              that.setState({
                snackMessage:
                    "Er is iets fout gegaan bij het opslaan van je instelling in de database. Probeer het later o" +
                    error.message
              });
            }
        );
  };



  handleSnackbarClose = () => {
    this.setState({ snackMessage: null });
  };

  goToApp() {
    window.location.replace("/");
  }

  handleLogout = () => {
    let that = this;
    if (this.state.parsePushSubscription != null) {
      // Deleting push subscription
      this.state.parsePushSubscription.destroy().then(() => {
        that.props.onLogOut();
      });
    } else {
      this.props.onLogOut();
    }
  };

  render() {
    let viewAs = "public";
    if (this.context.isAdmin) {
      viewAs = "Admin";
    } else if (this.context.isCommissielid) {
      viewAs = "Commissielid";
    } else if (this.context.isLid) {
      viewAs = "Lid";
    }
    const setViewAs = (event) => {
      viewAs = event.target.value;
      this.context.set("isAdmin", viewAs === "Admin");
      this.context.set("isCommissielid", viewAs === "Commissielid");
      this.context.set("isLid", viewAs === "Lid");
    }
    return (
      <div>
        <WindowTitleComponent title="Instellingen" />
        <Card className="paper-card login-card">
          <CardContent>
            <div className="flex-row-normal">
              <Typography className="inline-block" variant="h5" component="h1">
                Account
              </Typography>
            </div>
            <div className="inlog-card">
              Naam: {this.props.user.get("display_name")}
              <br />
              Gebruikersnaam: {this.props.user.get("username")}
              <br />
              Email: {this.props.user.get("email")}
              <br />
              <p>
                Het is ook mogelijk om deze agenda toe te voegen aan je digitale agenda! Kijk voor de link op <a href="https://www.desda.org/agenda/">https://www.desda.org/agenda/</a>.
              </p>
              <Button className="login-button" variant="contained" color="primary" onClick={this.handleLogout}>
                Uitloggen
              </Button>
            </div>
          </CardContent>
        </Card>

        <IfOnline>
          <Card className="paper-card login-card">
            <form>
              <CardContent className="inlog-card">
                <div className="inlog-card">
                  <Typography gutterBottom variant="h5" component="h1">
                    Pushmeldingen
                  </Typography>
                  {utils.checkCanReceivePush() ? (
                      <>
                        <Typography component="p">
                          Pas hier je instellingen voor pushmeldingen aan.
                        </Typography>
                        <FormControl variant="standard" component="fieldset">
                          <FormGroup>
                            <FormControlLabel
                                control={
                                  <Switch
                                      checked={
                                          this.state.parsePushSubscription && this.state.parsePushSubscription.get("OnNewEvent")
                                      }
                                      onChange={this.handleSwitchChange}
                                      name="pushMessagesForEvents"
                                  />
                                }
                                label="Pushmeldingen bij nieuwe activiteiten"
                            />
                            <FormControlLabel
                                control={
                                  <Switch
                                      checked={
                                          this.state.parsePushSubscription && this.state.parsePushSubscription.get("OnNewPost")
                                      }
                                      onChange={this.handleSwitchChange}
                                      name="pushMessagesForPosts"
                                  />
                                }
                                label="Pushmeldingen bij nieuwe berichten in De 37"
                            />
                          </FormGroup>
                        </FormControl>
                      </>
                  ) : (
                      <Typography component="p">
                        Je huidige browser biedt op dit moment geen ondersteuning voor pushmeldingen. Dit is helaas het
                        geval op iPhones, op een Androidtelefoon met een moderne browser (zoals Chrome) hoort dit te
                        werken.
                      </Typography>
                  )}
                </div>
              </CardContent>
            </form>
          </Card>

        </IfOnline>

        <Card className="paper-card login-card">
          <form>
            <CardContent className="inlog-card">
              <div className="inlog-card">
                <Typography gutterBottom variant="h5" component="h1">
                  Themainstellingen
                </Typography>
                <Typography component="p">
                  De DesdApp is er nu ook in een donker thema!
                </Typography>
                <FormControl variant="standard">
                  <FormControlLabel
                      control={
                        <Switch
                            checked={!!this.context.themeDarkmode}
                            onChange={(event) => { this.context.set("themeDarkmode", event.target.checked) }}
                        />
                      }
                      label="Donker thema"
                  />
                </FormControl>
              </div>
            </CardContent>
          </form>
        </Card>

        {this.context.isRealAdmin ?
            <Card className="paper-card login-card">
              <form>
                <CardContent className="inlog-card">
                  <div className="inlog-card">
                    <Typography gutterBottom variant="h5" component="h1">
                      Admininstellingen
                    </Typography>
                    <Typography component="p">
                      Instellingen speciaal voor beheerders.
                    </Typography>
                    <FormControl variant="standard">
                      <FormControlLabel
                          control={
                            <Switch
                                checked={!!this.context.showTestContent /* this looks weird but passing an undefined value causes problems*/}
                                onChange={(event) => { this.context.set("showTestContent", event.target.checked) }}
                            />
                          }
                          label="Toon testberichten"
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="view-site-as-select" variant="outlined">Bekijk site als</InputLabel>
                      <Select native id="view-site-as-select" value={viewAs} onChange={setViewAs} label="Bekijk site als" variant="outlined">
                        <option value={"Lid"}>Lid</option>
                        <option value={"Commissielid"}>Commissielid</option>
                        <option value={"Admin"}>Admin</option>
                      </Select>
                    </FormControl>
                  </div>
                </CardContent>
              </form>
            </Card>
            : null}

        <Card className="paper-card login-card">
          <CardHeader title="Updates van de www-Cie" subheader="Alles wat je (niet) over de DesdApp wilt weten..." />
          <CardContent className="inlog-card" style={{ paddingTop: 0 }}>
            <p style={{ margin: 0, marginBottom: "16px" }}>
              Bij bugs, vragen of opmerkingen, stuur een berichtje via de bugmelder in het menu. Wij ontvangen je bug
              onmiddelijk per mail.
            </p>
            <div className="inlog-card" dangerouslySetInnerHTML={{__html: this.state.markdownHtml}}/>
          </CardContent>
        </Card>
        <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.snackMessage != null}
            autoHideDuration={4000}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={this.state.snackMessage}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                style={{ padding: "4px" }}
                onClick={this.handleSnackbarClose}
                size="large">
                <CloseIcon />
              </IconButton>
            ]}
        />
      </div>
    );
  }
}

SettingsComponent.contextType = SettingsContext;

export default SettingsComponent;
