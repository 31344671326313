import { escapeAll, unescapeAll } from "./fieldescaping";

const standardEetlijstTime = new Date();
standardEetlijstTime.setHours(16);
standardEetlijstTime.setMinutes(0);
standardEetlijstTime.setSeconds(0);

export const emptyEvent = {
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    organizers: "",
    location: "",
    description: "",
    img: null,
    openForRegistration: false,
    teamRegistration: false,
    extraRegFields: "",
    maxTeamSize: "4",
    perPersonRegFields: "",
    nonPersonalRegFields: "",
    personalTeamRegFields: "",
    regDescription: "",
    regDeadlineEnabled: false,
    registrationDeadline: new Date(),
    regOpeningEnabled: false,
    registrationOpening: new Date(),
    hasEetlijst: false,
    eetlijstMenu: 0,
    eetlijstTime: new Date(standardEetlijstTime.getTime()),
    isTest: false,
    hasRegistrations: false,
    noShowPenalty: 0
};

export const eventFromParse = doc => {
    const data = doc.toJSON();
    data.startDate = new Date(data.startDate.iso || data.startDate);
    data.endDate = new Date(data.endDate.iso || data.endDate);

    data.teamRegistration = !!data.teamRegistration;
    data.maxTeamSize = data.maxTeamSize || "4";

    //for compatibility reasons extraRegFields contain all regFields, also the non personal ones
    let extraRegFieldsFiltered;
    if (data.extraRegFields && data.nonPersonalRegFields) {
        extraRegFieldsFiltered = data.extraRegFields.filter((el) => !data.nonPersonalRegFields.includes(el));
    } else {
        extraRegFieldsFiltered = data.extraRegFields;
    }
    data.extraRegFields = extraRegFieldsFiltered ? unescapeAll(extraRegFieldsFiltered).join(", ") : "";
    data.nonPersonalRegFields = data.nonPersonalRegFields ? unescapeAll(data.nonPersonalRegFields).join(", ") : "";
    data.perPersonRegFields = data.perPersonRegFields ? unescapeAll(data.perPersonRegFields).join(", ") : "";
    data.personalTeamRegFields = data.personalTeamRegFields ? unescapeAll(data.personalTeamRegFields).join(", ") : "";


    if (data.eetlijstTime && data.eetlijstTime.iso) {
        data.eetlijstTime = new Date(data.eetlijstTime.iso);
    } else {
        data.eetlijstTime = new Date(data.startDate.getTime());
        data.eetlijstTime.setHours(16);
        data.eetlijstTime.setMinutes(30);
        data.eetlijstTime.setSeconds(0);
    }
    data.eetlijstMenu = data.eetlijstMenu || 0;

    data.regDeadlineEnabled = Boolean(data.registrationDeadline && data.registrationDeadline.iso);
    if (data.regDeadlineEnabled) {
        data.registrationDeadline = new Date(data.registrationDeadline.iso);
    } else {
        data.registrationDeadline = new Date(data.startDate);
    }

    data.regOpeningEnabled = Boolean(data.registrationOpening && data.registrationOpening.iso);
    if (data.regOpeningEnabled) {
        data.registrationOpening = new Date(data.registrationOpening.iso);
    } else {
        data.registrationOpening = new Date();
    }

    data.isTest = data.test;
    delete data.test;

    data.organizers = data.organizers ? data.organizers.map(x => x.username).join(", ") : "";
    data.hasRegistrations = true;
    return data;
};

export const setParseEventValues = (parseEvent, event) => {
    parseEvent.set("title", event.title);
    parseEvent.set("location", event.location);
    parseEvent.set("startDate", event.startDate);
    parseEvent.set("endDate", event.endDate);
    parseEvent.set("description", event.description);
    parseEvent.set("openForRegistration", event.openForRegistration);
    parseEvent.set("extraRegFields", escapeAll(event.extraRegFields));
    parseEvent.set("nonPersonalRegFields", escapeAll(event.nonPersonalRegFields));
    parseEvent.set("perPersonRegFields", escapeAll(event.perPersonRegFields));
    parseEvent.set("personalTeamRegFields", escapeAll(event.personalTeamRegFields));
    parseEvent.set("teamRegistration", event.teamRegistration);
    parseEvent.set("maxTeamSize", event.maxTeamSize);
    parseEvent.set("regDescription", event.regDescription);
    parseEvent.set("registrationDeadline", event.registrationDeadline);
    parseEvent.set("registrationOpening", event.registrationOpening);
    parseEvent.set("img", event.img);
    parseEvent.set("test", event.isTest);
    parseEvent.set("hasEetlijst", event.hasEetlijst);
    parseEvent.set("eetlijstMenu", event.eetlijstMenu);
    parseEvent.set("eetlijstTime", event.eetlijstTime);
    parseEvent.set("uid", event.uid);
    parseEvent.set("organizers", event.organizers);
    parseEvent.set("noShowPenalty", event.noShowPenalty);
}
