import React, { useContext } from "react";
import Masonry from "react-masonry-css";

import {
	Dialog,
	DialogActions,
	DialogContent,
	useTheme,
	useMediaQuery,
	Button,
} from "@mui/material";

import { SettingsContext } from "../Settings";
import WindowTitleComponent from "../WindowTitleComponent";

import { applyFilters, EventFilters } from "./EventFilters";
import EventCard from "./EventCard";

const EventGrid = (props) => {
	const handleClose = () => {
		props.routeProps.history.push("/events");
	};

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const breakpointColumnsObj = {
		default: 4,
		1919: 3,
		1502: 2,
		866: 1,
	};

	const now = new Date();

	const settings = useContext(SettingsContext);

	let filteredEvents = props.sortedEvents
		.filter(
			(event) =>
				(!event.test || settings.showTestContent) && event.endDate > now
		)
		.filter(applyFilters(props));

	let popupEvent = props.sortedEvents.find(
		(event) => event.id === props.routeProps.match.params.id
	);

	return (
		<div>
			<WindowTitleComponent title="Activiteiten" />
			<EventFilters
				filterState={props.filterState}
				handleFilterChange={props.handleFilterChange}
			/>
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="my-masonry-grid"
				columnClassName="my-masonry-grid_column"
			>
				{filteredEvents.map((i) => {
					return (
						<EventCard
							event={i}
							key={i.title + i.user + i.startDate}
							doc={i.doc}
							editEvent={props.editEvent}
							user={props.user}
							routeProps={props.routeProps}
							handleRegisterEvent={props.handleRegisterEvent}
							handleEetlijstEvent={props.handleEetlijstEvent}
							handleRegistration={props.handleRegistration}
							isRegistered={props.isRegisteredFor(i.id)}
							classes={{ card: "eventcard-grid" }}
							makePost={props.makePost}
						/>
					);
				})}
			</Masonry>
			{popupEvent != null ? (
				<Dialog
					open={props.routeProps.match.params.id != null}
					onClose={handleClose}
					scroll="paper"
					classes={{ paper: "event-dialog" }}
					fullScreen={fullScreen}
					maxWidth={null}
				>
					<DialogContent style={{ padding: "0" }}>
						<EventCard
							classes={{ card: "event-popup" }}
							expanded={true}
							event={popupEvent}
							key={popupEvent.title + popupEvent.user + popupEvent.startDate}
							doc={popupEvent.doc}
							editEvent={props.editEvent}
							user={props.user}
							routeProps={props.routeProps}
							isRegistered={props.isRegisteredFor(popupEvent.id)}
							handleRegisterEvent={props.handleRegisterEvent}
							handleEetlijstEvent={props.handleEetlijstEvent}
							handleRegistration={props.handleRegistration}
							handleEetlijst={props.handleEetlijst}
							makePost={props.makePost}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="primary">
							Sluit
						</Button>
					</DialogActions>
				</Dialog>
			) : null}
		</div>
	);
};

export default EventGrid;
