import React, { useState, useContext } from "react";
import ReactMde from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import Parse from "parse";

import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Typography,
  FormControlLabel, Switch, Grid, useMediaQuery, useTheme, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import AreYouSure from "../AreYouSureComponent";
import ImageInputComponent, {saveImg} from "../ImageInputComponent";
import WindowTitleComponent from "../WindowTitleComponent";
import { linkifyMarkdifySanitize } from "../YouTube";
import { SettingsContext } from "../Settings";

import EventPicker from "./EventPicker";
import PollCreator from "./PollCreator";


const useStyles = makeStyles(() => ({
  paper: {
    border: "2px solid #dadce0",
    borderRadius: 10,
    padding: 16
  },
  inputField: {
    width: "100%"
  },
  dialogAction: {
    width: "100%"
  }
}));

const EditPostComponent = (props) => {
  const classes = useStyles(props);
  const [title, setTitle] = useState(props.post ? props.post.title : "");
  const [description, setDescription] = useState(props.post ? props.post.content : "");
  const [selectedMdTab, setSelectedMdTab] = useState("write");
  const user = Parse.User.current();
  const [authorName, setAuthorName] = useState(props.post ? props.post.authorName : user.get("display_name"));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [img, setImg] = useState(props.post ? props.post.image : null);
  const [changedImg, setChangedImg] = useState(null);
  const findEvent = eventId => {
    if (eventId) {
      for (let e of props.events) {
        if (e.id === eventId) {
          return e;
        }
      }
    } else {
      return null;
    }
  }
  const initialEvent = props.post ? props.post.associatedEvent : (props.postEvent? findEvent(props.postEvent.id): null);
  const [event, setEvent] = useState(initialEvent);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [wantDeleteOpen, setWantDeleteOpen] = useState(false);
  const startWithPoll = () => {
    if (!props.post) return false
    if (props.post.parse.get("showPoll") === undefined) return !! props.post.parse.get("poll")
    return props.post.parse.get("showPoll")
  }
  const [hasPoll, setHasPoll] = useState(startWithPoll());
  const [isTest, setIsTest] = useState(props.post ? props.post.parse.get("test") : false);
  const [poll, setPoll] = useState({});
  const [error, setError] = useState(undefined);
  const realAuthorName = (props.post && props.post.realAuthorName) ? props.post.realAuthorName : user.get("display_name");
  const settings = useContext(SettingsContext);
  const pollChanged = (q, a) => {
    setPoll({ question: q, answers: a });
  }
  const dialogTitle = props.post ? "Bericht bewerken" : "Bericht maken";
  const handleSetEvent = (eventId) => {
    setEvent(findEvent(eventId))
  }
  let doc = props.post;
  const close = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  const uploadResult = (success, message) => {
    if (props.upload) {
      props.upload(success, message);
    }
  }
  const remove = () => {
    doc.parse.destroy();
    close();
  }
  const createParsePoll = async () => {
    const Poll = Parse.Object.extend("Poll");
    let result = new Poll();
    result.set("question", poll.question.trim());
    let answers = poll.answers.map((x) => x.trim()).filter((x) => (x && x !== "") );
    result.set("options", answers);
    result.set("voteCount", 0);
    for (let i = 0; i < answers.length; i++) {
      result.set("voteCount" + i, 0);
    }
    let acl = new Parse.ACL();
    acl.setPublicReadAccess(false)
    acl.setPublicWriteAccess(false)
    acl.setRoleReadAccess("Lid", true);
    result.setACL(acl);
    await result.save();
    return result;
  }

  const validateInput = () => {
    const titleError = typeof title !== "string" || title.trim() === ""
    if(titleError){
      setError("title");
      return [false, "Een post moet een titel hebben"]
    }
    if(hasPoll && !(props.post && props.post.parse.get("poll"))) {
      if (!poll.question || poll.question.trim() === ""){
        setError("question")
        return [false, "Een poll moet een vraag hebben"]
      }
      let answers = poll.answers.map((x) => x.trim()).filter((x) => (x && x !== "") );
      if (answers.length === 0) {
        setError("answer");
        return [false, "Een poll moet minstens een antwoordoptie hebben"]
      }
    }
    return [true, "Prima"];
  }

  const done = async () => {
    if (!user) {
      uploadResult(false, "Geen user");
      return;
    }
    const [valid, message] = validateInput();
    if (!valid) {
      uploadResult(false, message);
      return;
    }
    let parsePoll = null;
    if (props.post && props.post.parse.get("poll")) {
      parsePoll = props.post.parse.get("poll");
    } else if (hasPoll) {
      parsePoll = await createParsePoll();
    }
    const Post = Parse.Object.extend("Post");
    let isCreating = (doc == null);
    let post = isCreating?  new Post() : doc.parse;
    post.set("title", title.trim());
    post.set("content", description.trim());
    if (changedImg !== null) {
      post.set("image", await saveImg("images_37", changedImg));
    }
    post.set("authorName", authorName.trim());
    post.set("realAuthorName", realAuthorName);
    post.set("poll", parsePoll);
    post.set("showPoll", hasPoll)
    post.set("test", isTest);
    if (event) {
      post.set("associatedEvent", event.doc);
    } else {
      post.set("associatedEvent", null);
    }
    if (isCreating) {
      post.set("authorID", user.id);
      let acl = new Parse.ACL();
      acl.setPublicReadAccess(true)
      acl.setRoleWriteAccess("Admin", true);
      acl.setWriteAccess(user, true)
      post.setACL(acl);
    }
    post.save().then(
        () => {
          uploadResult(true);
        },
        error => {
          // Execute any logic that should take place if the save fails.
          // error is a Parse.Error with an error code and message.
          alert("Failed to create new object, with error code: " + error.message);
          uploadResult(false, error.message);
        }
    );
  }
  let fields = [
    <TextField className={classes.inputField} label="Titel" value={title}
               onChange={(e) => { setTitle(e.target.value) }} variant="outlined"
               error={error==="title"} />,
    <ReactMde
        value={description}
        onChange={setDescription}
        selectedTab={selectedMdTab}
        onTabChange={setSelectedMdTab}
        l18n ={{write: "Bewerken", preview: "Voorbeeld"}}
        generateMarkdownPreview={markdown =>
        {
          return Promise.resolve(linkifyMarkdifySanitize(markdown)[0])
        }
        }
    />,
    <ImageInputComponent img={img} onDrop={setImg} setUploadingImage={setUploadingImage} onImgChange={setChangedImg} />,
    <TextField className={classes.inputField} label="Namens" value={authorName}
               onChange={(e) => { setAuthorName(e.target.value) }} variant="outlined" />,
    <Typography variant="caption" component="span" style={{position: "relative", left: 10 , top: -8, color: "grey"}}>
      <HelpOutlineIcon fontSize="small"  style={{position: "relative", top:5}}/> Je kan hiermee namens een commissie een bericht plaatsen. Je eigen naam wordt daarnaast ook getoond.
    </Typography>,
    <EventPicker events={props.events} setSelected={handleSetEvent} event={event} />,
    settings.isRealAdmin ? <FormControlLabel
        style={{ marginLeft: 0 }}
        control={
          <Switch
              color="primary"
              checked={isTest}
              onChange={(e) => { setIsTest(e.target.checked); }}
          />
        }
        label="Testbericht"
    /> : null,
    settings.isRealAdmin ? <Typography variant="caption" component="span" style={{position: "relative", left: 10 , top: -8, color: "grey"}}>
      <HelpOutlineIcon fontSize="small"  style={{position: "relative", top:5}}/> Een testbericht is alleen zichtbaar voor admins (als ze testberichten aan hebben staan in de instellingen) en veroorzaakt geen notificaties. Dit is handig bij het testen.
    </Typography> : null,
    <FormControlLabel
        style={{ marginLeft: 0 }}
        control={
          <Switch
              color="primary"
              checked={hasPoll}
              onChange={(e) => { setHasPoll(e.target.checked); }}
          />
        }
        label="Poll"
    />,
    hasPoll ? <PollCreator pollChanged={pollChanged}
                           questionError={error==="question"}
                           answerError={error==="answer"}
                           poll={props.post ? props.post.parse.get("poll") : null} /> : null
  ];
  let buttons = [
    <Button onClick={close} key={1}>
      Annuleren
    </Button>,
    <Button onClick={done} color="primary" autoFocus disabled={uploadingImage} key={2}>
      {props.post ? "Bericht opslaan" : "Bericht plaatsen"}
    </Button>
  ];
  if (doc) {
    buttons.unshift(
        <IconButton
          onClick={() => {setWantDeleteOpen(true);}}
          color="primary"
          autoFocus
          size="large">
          <DeleteIcon />
        </IconButton>
    );
  }
  let i = 0;
  fields = fields.map((x) => {
    i++;
    return x ? <Grid item xs={12} key={i}>{x}</Grid> : null;
  });
  let deleteWarning = "Weet u zeker dat u dit bericht wilt verwijderen? Dit kan niet ongedaan worden gemaakt.";
  if (props.post && props.post.poll) {
    deleteWarning = "Weet u zeker dat u dit bericht en bijbehorende poll permanent wilt verwijderen? Dit kan niet ongedaan worden gemaakt. ";
  }
  const areyousure = (
      <AreYouSure open={wantDeleteOpen} text={deleteWarning}
                  yes={remove} no={()=>{setWantDeleteOpen(false);}}
      />
  );
  if (props.dialog) {
    return (
        <div>
          <Dialog key={1} open={props.open} onClose={close} fullScreen={fullScreen}>
            <WindowTitleComponent title={dialogTitle}/>
            <DialogTitle>
              {dialogTitle}
            </DialogTitle>
            <DialogContent>
              <Grid container direction="column" spacing={2}>
                {fields}
              </Grid>
            </DialogContent>
            <DialogActions>
              {buttons}
            </DialogActions>
          </Dialog>
          {areyousure}
        </div>
    );
  }
  if (!props.open) return null;
  return (
      <Paper className={classes.paper}>
        <WindowTitleComponent title={dialogTitle}/>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <Typography variant="h6">
              {dialogTitle}
            </Typography>
          </Grid>
          {fields}
          <Grid item xs={12}>
            <DialogActions className={classes.dialogAction}>
              {buttons}
            </DialogActions>
          </Grid>
        </Grid>
        {areyousure}
      </Paper>
  );
};

export default EditPostComponent;
