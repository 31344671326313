import React, { useState } from "react";
import Parse from "parse";
import useMediaQuery from '@mui/material/useMediaQuery';

export const SettingsContext = React.createContext();

async function loadSettings(settings, setFullSettings) {
  let localSettings = null;
  if (window.localStorage.settings) {
    try {
      localSettings = JSON.parse(window.localStorage.settings);
      setFullSettings(oldSettings => ({...oldSettings, ...localSettings}));
    } catch (e) {
      console.log("Invalid local settings");
      window.localStorage.removeItem("settings");
    }
  }
  settings.set("loading", true);
  if (Parse.User.current()) {
    var query = new Parse.Query("_Role");
    query.equalTo("users", Parse.User.current());
    let results;
    try {
      results = await query.find();
    } catch (error) {
      console.error(error);
      return;
    }

    let roles = [];
    for (let i = 0; i < results.length; i++) {
      roles.push(results[i].getName());
    }
    settings.set("isRealAdmin", roles.includes("Admin"));

    if (!roles.includes("Admin") || !localSettings) {
      settings.set("isAdmin", roles.includes("Admin"));
      settings.set("isCommissielid", roles.includes("Commissielid"));
      settings.set("isLid", roles.includes("Lid"));

      settings.set("showTestContent", roles.includes("Admin"));
    }
  }
}

export const SettingsProvider = (props) => {
  const preferDarkmode = useMediaQuery('(prefers-color-scheme: dark)');
  let settings;
  let setFullSettings = () => { };
  const setSettings = (name, value) => {
    setFullSettings(prevSettings => {
      let newSettings = { ...prevSettings };
      newSettings[name] = value;
      window.localStorage.setItem("settings", JSON.stringify(newSettings));
      return newSettings;
    });
  }
  [settings, setFullSettings] = useState({ set: setSettings });
  if (!settings.loading) {
    loadSettings(settings, setFullSettings);
  }
  // we dont want to save the value of preferDarkmode to localStorage
  // and to only use it if localStorage doesnt already have this setting
  const renderSettings = {
    ...settings,
    themeDarkmode: settings.themeDarkmode ?? preferDarkmode,
  }
  return <SettingsContext.Provider value={renderSettings}>
    {props.children}
  </SettingsContext.Provider>
}