import React, { useEffect } from "react";
import Parse from "parse";

import { FormControlLabel, Switch, TextField } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

const queryRegistrationCount = async doc => {
  if (doc) {
    const params = { eventId: doc.id };
    return (await Parse.Cloud.run("getNonPersonalDataFromRegistration", params)).length;
  } else {
    return 0;
  }
}

const EditInschrijvingenComponent = (props) => {
  let event = props.event;
  let handleChange = props.handleChange;
  let setEvent = props.setEvent;
  let teamswitch;
  useEffect(async () => {
    let length = await queryRegistrationCount(props.doc);
    setEvent({ hasRegistrations: length !== 0 });
  }, []);

  if (event.hasRegistrations) {
    teamswitch = <FormControlLabel
      style={{ marginLeft: 0 }}
      control={
        <Switch
          color="primary"
          checked={event.teamRegistration}
          onChange={handleChange("teamRegistration", true)}
          value="teamRegistration"
          disabled
        />
      }
      label="Inschrijven in teams (er zijn al inschrijvingen dus dit kan niet meer veranderd worden)"
    />;
  } else {
    teamswitch = <FormControlLabel
      style={{ marginLeft: 0 }}
      control={
        <Switch
          color="primary"
          checked={event.teamRegistration}
          onChange={handleChange("teamRegistration", true)}
          value="teamRegistration"
        />
      }
      label="Inschrijven in teams"
    />;
  }
  return <>
    <FormControlLabel
      style={{ marginLeft: 0 }}
      control={
        <Switch
          color="primary"
          checked={event.openForRegistration}
          onChange={handleChange("openForRegistration", true)}
          value="openForRegistration"
        />
      }
      label="Open voor inschrijvingen"
    />
    {event.openForRegistration ? (
      <div className="inner-flex-column">
        {teamswitch}

        {event.teamRegistration &&
          <TextField
            type="number"
            label="Maximum teamgrootte"
            variant="outlined"
            value={event.maxTeamSize}
            onChange={handleChange("maxTeamSize")}
          />
        }
        <TextField
          label={event.teamRegistration ? "Inchrijfvelden voor het hele team" : "Extra inschrijfvelden (géén persoonsgegevens)"}
          variant="outlined"
          placeholder="Scheiden met komma's, markeer verplichte velden met een *"
          value={event.nonPersonalRegFields}
          onChange={handleChange("nonPersonalRegFields")}
        />
        {event.teamRegistration &&
          <TextField
            label="Inchrijfvelden voor het hele team (persoonsgegevens)"
            variant="outlined"
            placeholder="Scheiden met komma's, markeer verplichte velden met een *"
            value={event.personalTeamRegFields}
            onChange={handleChange("personalTeamRegFields")}
          />
        }

        <TextField
          label={event.teamRegistration ? "Inschrijfvelden per persoon (persoonsgegevens)" : "Extra inschrijfvelden (persoonsgegevens)"}
          variant="outlined"
          placeholder="Scheiden met komma's, markeer verplichte velden met een *"
          value={event.extraRegFields}
          onChange={handleChange("extraRegFields")}
        />
        {event.teamRegistration &&
          <TextField
            label={"Inschrijfvelden per persoon (géén persoonsgegevens)"}
            variant="outlined"
            placeholder="Scheiden met komma's, markeer verplichte velden met een *"
            value={event.perPersonRegFields}
            onChange={handleChange("perPersonRegFields")}
          />
        }

        <TextField
          label="Toelichting bij inschrijving"
          multiline
          variant="outlined"
          placeholder=""
          value={event.regDescription}
          onChange={handleChange("regDescription")}
        />

        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={
            <Switch
              color="primary"
              checked={event.regOpeningEnabled}
              onChange={handleChange("regOpeningEnabled", true)}
              value="regOpeningEnabled"
            />
          }
          label="Inschrijfopening aanzetten"
        />

        {event.regOpeningEnabled ? (
            <div className="inner-flex-column">
              <DateTimePicker
                  onChange={handleChange("registrationOpening", false)}
                  renderInput={props => <TextField variant="standard" label="Inschrijfopening" /> }
                  value={event.registrationOpening}
                  ampm={false}
              />
            </div>
          ) : null}

        <FormControlLabel
          style={{ marginLeft: 0 }}
          control={
            <Switch
              color="primary"
              checked={event.regDeadlineEnabled}
              onChange={handleChange("regDeadlineEnabled", true)}
              value="regDeadlineEnabled"
            />
          }
          label="Inschrijfdeadline aanzetten"
        />

        {event.regDeadlineEnabled ?
          (<>
            <div className="inner-flex-column">
              <DateTimePicker
                onChange={handleChange("registrationDeadline", false)}
                renderInput={props => <TextField variant="standard" label="Inschrijfdeadline" /> }
                value={event.registrationDeadline}
                ampm={false}
              />
            </div>
            <div className="inner-flex-column">
              <TextField
                onChange={handleChange("noShowPenalty", false)}
                label="Kosten bij te laat uitschrijven"
                value={event.noShowPenalty}
                variant="outlined"
                type="Number"
                placeholder="5,00"
              />
            </div>
          </>
          ) : null}
      </div>
    ) : null}
  </>;
}

export default EditInschrijvingenComponent;
