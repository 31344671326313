import React, { Component } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import Parse from "parse";

import {
	Button,
	Fab,
	FormControlLabel,
	Switch,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Zoom,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import { SettingsContext } from "../Settings";
import WindowTitleComponent from "../WindowTitleComponent";

import EditInschrijvingenComponent from "./EditInschrijvingenComponent";
import { emptyEvent, eventFromParse, setParseEventValues } from "./Event";
import SelectEetlijstComponent from "./SelectEetlijstComponent";
import EditBasicEventDataComponent from "./EditBasicEventDataComponent";
import { saveImg } from "../ImageInputComponent";

import "./EditEventComponent.css";

const emptyState = {
	event: emptyEvent,
	open: false,
	doc: null,
	uploadingImage: false,
	wantDeleteOpen: false,
	showFab: true,
	changedImg: null,
};

class EditEventComponent extends Component {
	constructor(props) {
		super(props);

		this.prevScrollTop = 0;
		let state = { ...emptyState };
		state.doc = props.doc;
		if (props.doc !== null) {
			state.event = eventFromParse(props.doc);
			state.open = true;
		}
		if (props.newEvent !== null) {
			state.open = true;
			if (props.newEvent.startDate) {
				state.startDate = props.newEvent.startDate;
				state.endDate = props.newEvent.endDate;
			}
		}
		this.state = state;
	}

	setEvent(obj) {
		let event = { ...this.state.event, ...obj };
		this.setState({ event });
	}

	componentDidMount() {
		window.addEventListener("scroll", this.hideOrShowFab, false);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.hideOrShowFab);
	}

	hideOrShowFab = () => {
		const scrollTop = document.documentElement.scrollTop;
		if (scrollTop > this.prevScrollTop) {
			if (this.state.showFab) this.setState({ showFab: false });
		} else if (scrollTop < this.prevScrollTop) {
			if (!this.state.showFab) {
				this.setState({ showFab: true });
			}
		}
		this.prevScrollTop = scrollTop;
	};

	handleClose = () => {
		this.setState(emptyState);
		this.props.handleClose();
	};

	handleUpload = async () => {
		this.uploadEvent(
			await this.cleanInput({
				...this.state.event,
				doc: this.state.doc,
				changedImg: this.state.changedImg,
			})
		).catch((error) => {
			alert(
				"Het aanpassen/aanmaken van het evenement is mislukt: " + error.message
			);
			//TODO: maak een betere foutmelding
		});
		this.handleClose();
	};

	cleanInput = async (event) => {
		event.maxTeamSize = parseInt(event.maxTeamSize) || 4;
		event.extraRegFields = this.splitTrim(event.extraRegFields);
		event.nonPersonalRegFields = this.splitTrim(event.nonPersonalRegFields);
		event.perPersonRegFields = this.splitTrim(event.perPersonRegFields);
		event.personalTeamRegFields = this.splitTrim(event.personalTeamRegFields);
		if (!event.teamRegistration) {
			event.extraRegFields = event.nonPersonalRegFields.concat(
				event.extraRegFields
			);
		}
		if (!event.openForRegistration) {
			event.regDeadlineEnabled = false;
			event.regOpeningEnabled = false;
		}
		event.registrationDeadline = event.regDeadlineEnabled
			? event.registrationDeadline
			: null;
		event.registrationOpening = event.regOpeningEnabled
			? event.registrationOpening
			: null;
		event.organizers = this.splitTrim(event.organizers);
		event.noShowPenalty = Number(event.noShowPenalty);
		if (event.changedImg !== null) {
			event.img = await saveImg("images_event", event.changedImg);
		}
		return event;
	};

	splitTrim = (listString) => {
		const list = listString.split(",").map((x) => x.trim());
		if (list.length === 1 && list[0].length === 0) {
			return [];
		}
		return list;
	};

	updateRegistrationACL = (eventId, organizerIds) => {
		const query = new Parse.Query("Registration");
		query.equalTo("eventId", eventId);
		query.findAll({}).then((results) => {
			results.forEach((reg) => {
				this.setRegACL(reg, organizerIds);
			});
		});
	};

	setRegACL = (reg, organizerIds) => {
		const regACL = new Parse.ACL();
		regACL.setRoleWriteAccess("Admin", true);
		regACL.setRoleReadAccess("Admin", true);
		for (const id of organizerIds) {
			regACL.setWriteAccess(id, true);
			regACL.setReadAccess(id, true);
		}
		regACL.setWriteAccess(reg.get("uid"), true);
		regACL.setReadAccess(reg.get("uid"), true);
		reg.setACL(regACL, {});
		reg.save();
	};

	setEventACL = (event, ids) => {
		const eventACL = new Parse.ACL();
		eventACL.setRoleWriteAccess("Admin", true);
		eventACL.setRoleReadAccess("Lid", true);
		for (const id of ids) {
			eventACL.setWriteAccess(id, true);
		}
		event.setACL(eventACL, {});
	};

	uploadEvent = async (event) => {
		let currentUser = Parse.User.current();
		if (!currentUser) return;

		const Event = Parse.Object.extend("Event");
		const isCreating = event.doc == null;
		const parseEvent = isCreating ? new Event() : event.doc;
		const uid = isCreating ? currentUser.id : parseEvent.get("uid");
		event.uid = uid;
		const oldOrganizers = parseEvent.get("organizers")
			? parseEvent.get("organizers")
			: [];
		let organizersObj = await this.queryUsersByUsername(event.organizers);
		event.organizers = organizersObj;
		setParseEventValues(parseEvent, event);

		const organizerIds = event.organizers.map((x) => x.id);
		if (uid) organizerIds.push(uid);
		const equal = this.arrayEquals(organizersObj, oldOrganizers);
		if (isCreating || !equal) this.setEventACL(parseEvent, organizerIds);
		if (!isCreating && !equal)
			this.updateRegistrationACL(parseEvent.id, organizerIds);

		parseEvent.save().catch((error) => {
			alert("Failed to create new object, with error code: " + error.message);
		});
	};

	async queryUsersByUsername(usernames) {
		const params = { usernames: usernames };
		return await Parse.Cloud.run("getUIDsForUsernames", params);
	}

	arrayEquals(a, b) {
		if (a.length !== b.length) {
			return false;
		}
		for (let i = 0; i < a.length; i++) {
			if (a[i].id !== b[i].id) {
				return false;
			}
		}
		return true;
	}

	handleDelete = () => {
		this.setState({ wantDeleteOpen: true });
	};

	handleDeleteClose = () => {
		this.setState({ wantDeleteOpen: false });
	};

	handleConfirmDelete = () => {
		this.state.doc.destroy();
		this.handleDeleteClose();
		this.handleClose();
	};

	handleChange =
		(prop, isBoolean = false) =>
		(event) => {
			const state = {};
			if (event.target) {
				if (isBoolean) state[prop] = event.target.checked;
				else state[prop] = event.target.value;
			} else {
				state[prop] = event;
			}
			this.setEvent(state);
		};

	propertySwitch = (label, prop, visible) => {
		if (!visible) return null;
		return (
			<FormControlLabel
				style={{ marginLeft: 0 }}
				control={
					<Switch
						color="primary"
						checked={this.state.event[prop]}
						onChange={this.handleChange(prop, true)}
						value={prop}
					/>
				}
				label={label}
			/>
		);
	};

	render() {
		const fullScreen = false;
		// const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
		if (!this.props.online) return <div> </div>;

		let dialogTitle =
			this.state.doc != null ? "Evenement updaten" : "Evenement maken";
		let newEventButton = (this.context.isAdmin ||
			this.context.isCommissielid) && (
			<Zoom in={this.state.showFab} key="showFab" timeout={300}>
				<Fab
					color="primary"
					aria-label="Add"
					onClick={this.props.handleClickOpen}
					className="add-fab"
				>
					{" "}
					<AddIcon />
				</Fab>
			</Zoom>
		);

		return (
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<>
					{newEventButton}
					<Dialog
						fullScreen={fullScreen}
						open={this.state.open}
						onClose={this.handleClose}
						aria-labelledby="responsive-dialog-title"
					>
						<WindowTitleComponent title={dialogTitle} />
						<DialogContent classes={{ root: "content-dialog" }}>
							<DialogTitle
								style={{ paddingTop: "0" }}
								id="responsive-dialog-title"
							>
								{dialogTitle}
							</DialogTitle>
							<DialogContentText
								style={{ paddingLeft: "12px", paddingBottom: "10px" }}
							>
								Geef je evenement een duidelijke titel, aansprekende foto en
								enthousiaste beschrijving.
							</DialogContentText>
							<div className="flex-column">
								<EditBasicEventDataComponent
									event={this.state.event}
									setEvent={this.setEvent.bind(this)}
									handleChange={this.handleChange}
									setUploadingImage={(busy) => {
										this.setState({ uploadingImage: busy });
									}}
									setChangedImg={(img) => {
										this.setState({ changedImg: img });
									}}
								/>
								{this.propertySwitch(
									"Testactiviteit",
									"isTest",
									this.context.isRealAdmin
								)}
								<SelectEetlijstComponent
									event={this.state.event}
									setEvent={this.setEvent.bind(this)}
									handleChange={this.handleChange}
								/>
								<EditInschrijvingenComponent
									event={this.state.event}
									setEvent={this.setEvent.bind(this)}
									handleChange={this.handleChange}
									doc={this.state.doc}
								/>
							</div>
						</DialogContent>

						<DialogActions>
							{this.state.doc != null ? (
								<IconButton
									onClick={this.handleDelete}
									color="default"
									size="large"
								>
									<DeleteIcon />
								</IconButton>
							) : null}

							<Button onClick={this.handleClose}>Annuleren</Button>
							<Button
								onClick={this.handleUpload}
								color="primary"
								autoFocus
								disabled={this.state.uploadingImage}
							>
								{this.state.doc != null ? (
									<div>Evenement updaten</div>
								) : (
									<div> Evenement maken</div>
								)}
							</Button>
						</DialogActions>
					</Dialog>

					<Dialog
						open={this.state.wantDeleteOpen}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						onClose={this.handleDeleteClose}
						style={{ zIndex: 10000 }}
					>
						<DialogTitle id="alert-dialog-title">
							{"Evenement verwijderen?"}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Weet u zeker dat u dit evenement wilt verwijderen? Dit kan niet
								ongedaan worden gemaakt.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleDeleteClose} color="primary">
								Annuleren
							</Button>
							<Button
								onClick={this.handleConfirmDelete}
								color="primary"
								autoFocus
							>
								Verwijderen
							</Button>
						</DialogActions>
					</Dialog>
				</>
			</LocalizationProvider>
		);
	}
}

EditEventComponent.contextType = SettingsContext;

export default EditEventComponent;
