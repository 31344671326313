export function formatDate(date, useWords = false, useDays = false, refDay = null) {
  const dayNames = ["zo.", "ma.", "di.", "wo.", "do.", "vr.", "za."];
  const monthNames = ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."];
  let noDate = false;
  let showYear = false;

  if (refDay) {
    var refDate = refDay;
    noDate =
      refDate.getDate() === date.getDate() &&
      refDate.getMonth() === date.getMonth() &&
      refDate.getFullYear() === date.getFullYear();
    showYear = refDate.getFullYear() !== date.getFullYear();
  }
  let retString = "";
  if (!noDate) {
    if (useWords) {
      retString = retString.concat((useDays ? (dayNames[date.getDay()] + " ") : ("")) + date.getDate() + " " + monthNames[date.getMonth()] + " ");
      if (showYear)
        retString = retString.concat(date.getFullYear() + " ");
    } else {
      retString = retString += ("0" + date.getDate()).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2);
      if (showYear)
        retString = retString.concat("-" + date.getFullYear() + " ");
      else {
        retString += " ";
      }
    }
  }
  return retString + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
}
export default formatDate;

/**
 * Format een date relatief ten opzichte van de huidige datum.
 * Stel vandaag is 11/03/2021 20:30 en de date is 11/03/2021 19:30,
 * dan moet deze functie "1 uur geleden" returnen.
 */
export function relativeDate(date) {
  const index = ["sec", "min", "hour", "day", "week", "month", "year"];
  const singular = ["seconde", "minuut", "uur", "dag", "week", "maand", "jaar"];
  const plural = ["seconden", "minuten", "uur", "dagen", "weken", "maanden", "jaren"];

  let diff = {};
  diff.ms = new Date() - date;
  diff.sec = Math.round(diff.ms / 1000);
  diff.min = diff.sec / 60;
  diff.hour = diff.min / 60;
  diff.day = diff.hour / 24;
  diff.week = diff.day / 7;
  diff.month = diff.day / 29.6;
  diff.year = diff.day / 365;

  for (let i = 6; i > 0; i--) {
    if (diff[index[i]] >= 1) {
      let r = Math.round(diff[index[i]]);
      if (r > 1) {
        return `${r} ${plural[i]} geleden`;
      } else {
        return `${r} ${singular[i]} geleden`;
      }
    }
  }

  //Minder dan een seconde geleden.
  return "zojuist";
}

export function purpleFriday(date) {
  if (date.getMonth() === 11 && date.getDay() === 5) { //het is december en vrijdag
    //het is de tweede vrijdag
    return (date.getDate() >= 8) && (date.getDate() <= 14)
  } else {
    return false;
  }
}