import React, {useContext, useState, useRef, useEffect} from "react";
import randomColor from "randomcolor";

import {Typography, IconButton, Button, Avatar, Grid, Card, Hidden, Collapse} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import formatDate from "../FormatDate";
import lazyLoadIfNeeded from "../FadeIn";
import { SettingsContext } from "../Settings"
import { linkifyMarkdifySanitize } from "../YouTube";
import CommentsPreview from "./CommentsPreview";
import Poll from "./Poll";

const useStyles = makeStyles(() => ({
  imageDiv: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    marginTop: "1em"
  },
  card: {
    display: "block",
    position: "relative",
    height: "fit-content",
    border: "1px solid #dadce0",
    "border-radius": "10px",
    padding: "1em"
  },
  alignright: {
    margin: "0 auto 0 0"
  },
  ytcontainer: {
    width: "100%",
    position: "relative",
    paddingBottom: "56.25%"
  },
  yt: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%"
  }
}));

function ReadMoreSwitch(props) {
  const iconStyle = {width: "100%", cursor: "pointer"}
  return props.expanded
      ? <ExpandLessIcon style={iconStyle} fontSize={"large"} onClick={props.onChange}/>
      : <ExpandMoreIcon style={iconStyle} fontSize={"large"} onClick={props.onChange}/>
}

export function PostCard(props) {
  const date = formatDate(new Date(props.post.createdAt), true, false, new Date());
  const title = props.post.title;
  const settings = useContext(SettingsContext);
  let image = props.post.image;
  const [author, extraName] = (() => {
    if (props.post.realAuthorName) {
      if (props.post.authorName.trim() && props.post.authorName.trim() !== props.post.realAuthorName.trim()) {
        return [props.post.authorName.trim(), props.post.realAuthorName.trim()];
      }
      return [props.post.realAuthorName.trim(), null];
    } else if (props.post.authorName.trim()) {
      return [props.post.authorName.trim(), null];
    } else {
      return ["?", null];
    }
  })();
  const classes = useStyles(props);
  const handleEditClick = () => {
    if (props.edit) {
      props.edit(props.post);
    }
  };
  const canUserEditPost = () => {
    return (
        props.user.id === props.post.authorID || settings.isAdmin
    );
  };
  const navigateEvent = () => {
    props.routeProps.history.push("/events/" + props.post.associatedEvent.objectId);
  };
  let [sanitizedDescription, youtube] = linkifyMarkdifySanitize(
      props.post.content,
      {
        width: "",
        height: ""
      },
      classes.ytcontainer,
      classes.yt
  );
  let description = (
      <Typography
          component="p"
          dangerouslySetInnerHTML={{
            __html: sanitizedDescription
          }}
      />
  );
  if (image) {
    image = (
        <div className={classes.imageDiv}>
          {lazyLoadIfNeeded(title, image.url, classes.image, true, null, null)}
        </div>
    );
  }
  let poll = null;
  const showPoll = () => {
    if (!props.post) return false
    if (props.post.showPoll === undefined) return !! props.post.poll
    return props.post.showPoll
  };
  if (showPoll()) {
    poll = <Poll poll={props.post.parse.get("poll")} />
  }
  let activityButton = () => null;
  if (props.post.associatedEvent) {
    activityButton = full => (
        <Button variant="contained" color="primary" fullWidth={full} onClick={navigateEvent}>Ga naar de activiteit!</Button>
    );
  }
  const avatar = (
      <Avatar style={{
        backgroundColor: randomColor({ luminosity: "dark", seed: author }),
        height: 35,
        width: 35
      }}>
        {author.split(' ').map(s => {return s[0]}).join('')}
      </Avatar>
  );
  let editButton = null;
  if (canUserEditPost()) {
    editButton = (
        <IconButton
          aria-label="Bewerken"
          color="primary"
          onClick={handleEditClick}
          style= {{paddingTop: "8px"}}
          size="large">
          <EditIcon />
        </IconButton>
    );
  }
  let authorInformation = align => (
      <Grid container direction="row" justifyContent={align} alignItems="center" spacing={1}>
        <Grid item>
          {avatar}
        </Grid>
        <Grid item>
          <Grid container direction="column">
            <Grid item style={{ textAlign: "right" }}>
              {author}
            </Grid>
            {
              extraName ?
                  (<Grid item xs>
                    {extraName}
                  </Grid>)
                  : null
            }
            <Grid item style={{ textAlign: "right" }}>
              {date}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );

  const [collapseIn, setCollapseIn] = useState(true);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const contentGrid = useRef(null);

  let readMoreSwitch = () => (collapsedHeight !== 0) &&
      <ReadMoreSwitch expanded={collapseIn} onChange={() => {setCollapseIn((prev) => !prev)}}/>

  const MAX_HEIGHT = 300;
  useEffect(() => {
    if (contentGrid.current.clientHeight > MAX_HEIGHT) {
      setCollapseIn(false);
      setCollapsedHeight(MAX_HEIGHT);
    }
  }, []);

  return (
    <Card className={classes.card} elevation={0}>
      <Grid container spacing={1} direction="row" alignItems="center">
        <Hidden smUp>
          {authorInformation("flex-start")}
          {/* <Grid item container style={{ color: "#9b9b9b" }} xs={12} direction="row" alignItems="center" justifyContent="space-between">
                      <Grid item xs style={{ marginRight: 10, minWidth: 35 }}>
                          {avatar}
                      </Grid>
                      <Grid container justifyContent="space-between">
                          <Grid item xs style={{flexBasis:"auto"}}>
                              {extraName ? author + " (" + extraName + ")" : author}
                          </Grid>
                          <Grid item xs style={{ textAlign: "right", flexBasis:"auto"}}>
                              {date}
                          </Grid>
                      </Grid>
                  </Grid> */}
          <Grid item xs={12} sm={8} md={8}>
            <Typography variant="h6" component="h1" display="inline">
              {title}
            </Typography>
            {editButton}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid item container xs={12} justifyContent="space-between" direction="row" alignItems="flex-start">
            <Grid item>
              <Typography variant="h6" component="h1" display="inline">
                {title}
              </Typography>
              {editButton}
            </Grid>
            <Grid item>
              {authorInformation("flex-end")}
            </Grid>
          </Grid>
        </Hidden>
        <Grid item xs={12} className="markdown-rendered" ref={contentGrid}>
          <div style={{position: "relative"}}>
            <Collapse orientation="vertical" in={collapseIn} collapsedSize={collapsedHeight}>
              {description}
              {image}
              {youtube}
              {poll}
            </Collapse>
            {(!collapseIn) && <div style={{width: "100%", height: 50, backgroundImage: `linear-gradient(to bottom, transparent, ${settings.themeDarkmode ? "#1F1F1F" : "#FFF"})`, position: "absolute", bottom: 0}}/>}
          </div>
        </Grid>
        <Grid item xs={12} style={{ padding: 0 }}>
          {readMoreSwitch()}
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} sm={6} style={{ marginTop: "8px" }}>
            {activityButton(false)}
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid item xs={12} sm={6}>
            {activityButton(true)}
          </Grid>
        </Hidden>
        <Grid item container justifyContent="flex-end" xs={12} sm={6} style={{ marginTop: "8px" }}>
          {props.commentsPreview ? <CommentsPreview parentId={props.post.objectId} /> : null}
        </Grid>
      </Grid>
    </Card>
  );
}

export default PostCard;
