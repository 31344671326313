import React, { useState, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import Parse from "parse";

import { AppBar, Toolbar, Typography, Button, IconButton, Snackbar, styled } from "@mui/material";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloseIcon from "@mui/icons-material/Close";

import * as utils from "./DataUtils";
import { IfOffline, IfOnline } from "./OnlineContext";
import HistoryBackButton from "./HistoryBackButton";


const RaisedAppBar = styled(AppBar)(({theme}) => ({
  zIndex: theme.zIndex.drawer + 1
}));

function DesdAppBar(props) {
  const [snackMessage, setSnackMessage] = useState(null);
  const [parsePushSubscription, setParsePushSubscription] = useState(null);
  const [installPrompt, setInstallPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", deferredPrompt => {
      // Update UI notify the user they can add to home screen
      showInstallPromotion(deferredPrompt);
    });
  }, []); //only at the start

  useEffect(() => {
    let currentUser = Parse.User.current();
    if(currentUser)
    {
      utils.getPushSubscriptionsFromParse(currentUser.id).then(subscription => {
        if (JSON.stringify(parsePushSubscription) !== JSON.stringify(subscription)) {
          setParsePushSubscription(subscription);
        }
      });
    }

  });



  const showInstallPromotion = installPrompt => {
    if (window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone === true) {
      //display-mode is standalone
      //is already installed, don't know if this can happen?
    } else {
      setInstallPrompt(installPrompt);
    }
  };


  const handleNotificationToggle = (property, id) => {
    if (!utils.checkCanReceivePush()) return null;

    utils
        .askPermissionForPush()
        .then(function(permissionResult) {
          if (permissionResult !== "granted") {
            setSnackMessage(
                "Er is iets fout gegaan bij het geven van toestemming voor pushmeldingen. Probeer het opnieuw."
            );
            return null;
          } else {
            return subscribeUserToPush();
          }
        })
        .then(subscription => {
          if (subscription) {
            let switchChange = {};
            switchChange[property] = id;
            savePushSubscriptionInParse(subscription, switchChange);
          }
        });
  };

  const subscribeUserToPush = () => {
    return utils.getPushSubscriptions(true).then(function(pushSubscription) {
      if (pushSubscription == null) {
        setSnackMessage(
            "Er is iets fout gegaan bij het instellen van pushmeldingen. Probeer het later opnieuw."
        );
      }
      return pushSubscription;
    });
  };

  const savePushSubscriptionInParse = (pushSubscription, switchChange) => {
    utils
        .savePushSubscriptionInParse(parsePushSubscription, pushSubscription, switchChange, Parse.User.current())
        .then(
            subscription => {
              setParsePushSubscription(subscription);
              setSnackMessage("Je pushinstelling is aangepast!");
            },
            error => {
              setSnackMessage(
                  "Er is iets fout gegaan bij het opslaan van je instelling in de database. Probeer het later opnieuw" +
                  error.message
              );
            }
        );
  };

  const handleSnackbarClose = () => {
    setSnackMessage(null);
  };

  const installToHomeScreen = () => {
    installPrompt.prompt();
    // Wait for the user to respond to the prompt
    installPrompt.userChoice.then(choiceResult => {
      if (choiceResult.outcome === "accepted") {
        // User accepted the A2HS prompt
        setInstallPrompt(null);
      } else {
        // User dismissed the A2HS prompt
      }
    });
  };

  let position = "static";
  if (props.useAppDrawer) position = "fixed";


  return (
    <div>
      <RaisedAppBar position={position} color="primary">
        <Toolbar>
          <Route
              path={[
                "/settings",
                "/photos/:id",
                "/feedback",
                "/bestuursbus",
                "/gehoord",
                "/calendar",
                "/de37/:id"
              ]}
              render={() => <HistoryBackButton />}
          />

          <Typography
              variant="h6"
              color="inherit"
              style={{ flexGrow: 1, overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {props.appTitle}
          </Typography>
          <IfOffline>
            <Button color="inherit" variant="outlined" style={{ color: "red" }}>
              Offline
            </Button>
          </IfOffline>
          {installPrompt ? (
              <IfOnline>
                <Button color="inherit" variant="outlined" onClick={installToHomeScreen}>
                  Installeren
                </Button>
              </IfOnline>
          ) : null}

          <IfOnline>
            {utils.checkCanReceivePush() ? (
                <Switch>
                  <Route
                      path={["/de37/:id"]}
                      className="route-element"
                      render={routeProps => (
                          <IconButton
                            aria-label="Toggle Notifications"
                            aria-controls="menu-appbar"
                            color="inherit"
                            onClick={() =>
                                handleNotificationToggle(
                                    "togglePushMessagesForCommentParentId",
                                    routeProps.match.params.id
                                )
                            }
                            size="large">
                            {parsePushSubscription &&
                            parsePushSubscription.has("OnNewCommentParentIds") &&
                            parsePushSubscription
                                .get("OnNewCommentParentIds")
                                .includes(routeProps.match.params.id) ? (
                                <NotificationsActiveIcon />
                            ) : (
                                <NotificationsOffOutlinedIcon />
                            )}
                          </IconButton>
                      )}
                  />
                </Switch>
            ) : null}
          </IfOnline>
          <IconButton
            aria-label="Refresh"
            aria-controls="menu-appbar"
            color="inherit"
            onClick={props.performRefresh}
            size="large">
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </RaisedAppBar>
      <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          open={snackMessage != null}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={snackMessage}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              style={{ padding: "4px" }}
              onClick={handleSnackbarClose}
              size="large">
              <CloseIcon />
            </IconButton>
          ]}
      />
    </div>
  );
}

export default DesdAppBar;
