import React from "react";
import { Route, Link } from "react-router-dom";
import PropTypes from "prop-types";

import { Drawer, Divider, ListItem, BottomNavigation, BottomNavigationAction, List, ListItemIcon, ListItemText}
  from "@mui/material";
import { withStyles } from "@mui/styles";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { OnlineDetector } from "./OnlineContext";


const styles = theme => ({
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar
});


function ResponsiveNavigation(props) {
  const {menu, useAppDrawer, classes} = props;
  return <Route
      exact
      path={props.matchWithSubpages(menu.mainmenu.concat([{ to: "/submenu" }], menu.submenu).map(x => x.to))}
      render={({ match }) => {
        let urlToMatch = match.url;
        let showAppDrawerAlsoInSubpagesOf = ["/calendar", "/events"];
        //show appdrawer when viewing event popup in /calendar or /events
        for (let url of showAppDrawerAlsoInSubpagesOf) {
          if (urlToMatch.startsWith(url)) {
            urlToMatch = url;
            break;
          }
        }

        let v = menu.mainmenu.concat([{ to: "/submenu" }], menu.submenu).findIndex(x => x.to === urlToMatch);

        if (v < 0) v = 9999;
        return (
            <div>
              {!useAppDrawer && v < menu.mainmenu.length + 1  ? (
                  <OnlineDetector
                      render={online => {
                        let menuItems = props.menu.mainmenu.map(item => {
                          if (item.onlyOnline && !online) return null;

                          return (
                              <BottomNavigationAction
                                  key={item.label}
                                  label={item.label}
                                  component={Link}
                                  to={item.to}
                                  icon={item.icon}
                              />
                          );
                        });

                        return (
                            <BottomNavigation value={v} showLabels className="bottom-nav" style={{ zIndex: "100" }}>
                              {menuItems}
                              <BottomNavigationAction
                                  label="Meer"
                                  component={Link}
                                  to="/submenu"
                                  icon={<MoreHorizIcon />}
                              />
                            </BottomNavigation>
                        );
                      }}
                  />
              ) : null}
              {useAppDrawer ? (
                  <OnlineDetector
                      render={online => {
                        let mainmenuItems = menu.mainmenu.map((item, i) => {
                          if (item.onlyOnline && !online) return null;

                          return (
                              <MenuItemLink
                                  to={item.to}
                                  referer={item.referer}
                                  icon={item.icon}
                                  primary={item.label}
                                  key={item.label}
                                  selected={v === i}
                              />
                          );
                        });

                        let submenuItems = menu.submenu.map((item, i) => {
                          if (item.onlyOnline && !online) return null;

                          return (
                              <MenuItemLink
                                  to={item.to}
                                  referer={item.referer}
                                  icon={item.icon}
                                  primary={item.label}
                                  key={item.label}
                                  selected={v === i + 1 + menu.mainmenu.length} // plus 1 to compensate for missing submenu button
                              />
                          );
                        });

                        return (
                            <Drawer
                                className="drawer"
                                variant="permanent"
                                classes={{
                                  paper: "drawer-paper"
                                }}
                                anchor="left"
                            >
                              <div className={classes.toolbar} />
                              <List>{mainmenuItems}</List>
                              <Divider />
                              <List>{submenuItems}</List>
                            </Drawer>
                        );
                      }}
                  />
              ) : null}
            </div>
        );
      }}
  />
}
export default  withStyles(styles)(ResponsiveNavigation);

function MenuItemLink(props) {
  const { icon, primary, to, referer, selected } = props;

  const renderLink = React.useMemo(
      () =>
          React.forwardRef((itemProps, ref) =>
              /^https?:\/\//.test(to) ? ( //https://github.com/ReactTraining/react-router/issues/1147#issuecomment-32411131
                  // eslint-disable-next-line
                  <a target="_blank" rel="noopener" referrerPolicy={referer ? "no-referrer-when-downgrade" : "no-referrer"} href={to} {...itemProps} />
              ) : (
                  <Link to={to} ref={ref} {...itemProps} />
              )
          ),
      [to, referer]
  );

  return (
      <MenuItem button component={renderLink} selected={selected}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </MenuItem>
  );
}

MenuItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  referer: PropTypes.bool,
  selected: PropTypes.bool.isRequired
};

const MenuItem = withStyles(theme => ({
  root: {
    "&$selected": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.primary.main
      }
    }
  },
  selected: {}
}))(ListItem);