import React from "react";
import { Link, Prompt } from "react-router-dom";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Parse from "parse";

import { Card, CardContent, CircularProgress } from "@mui/material";

import WindowTitleComponent from "../WindowTitleComponent";


//Wrap de Album component in een 'high level' function component zodat we hooks kunnen gebruiken.
const withBackHook = Component => {
  return props => {
    const { closeLightbox } = useLightbox();
    return <Component {...props} closeLightbox={closeLightbox} />;
  };
};

class Album extends React.Component {
  constructor(props) {
    super(props);
    this.state = { status: "loading"};
    this.closeLightbox = props.closeLightbox;
  }

  async componentDidMount() {
    let currentUser = Parse.User.current();
    if (currentUser) {
      let npages = 1;
      let page = 1;
      while (page <= npages) {
        let data = await Parse.Cloud.run("getFlickrAlbum", {album: this.props.album, page: page});
        if (data.stat === "fail") {
          this.setState({ status: "fail" });
          break;
        }
        else if (data.stat === "ok") {
          if (page === 1) {
            this.setState({
              title: data.photoset.title,
              photos: data.photoset.photo
            });
            this.props.updateAppTitle(data.photoset.title);
            npages = Math.ceil(data.photoset.total/data.photoset.perpage);
          }
          else
            this.setState({ photos: [...this.state.photos, ...data.photoset.photo] });
        }
        page += 1;
      }
      if (this.state.status === "loading")
        this.setState({ status: "ok" });
    }
  }

  render() {
    const srloptions = {
      settings: {
        disablePanzoom: false,
        slideAnimationType: "both",
        slideSpringValues: [1200, 150],
        slideTransitionSpeed: 0.3,
        slideTransitionTimingFunction: "linear",
        hideControlsAfter: false
      },
      buttons: {
        showAutoplayButton: false,
        showCloseButton: true,
        showDownloadButton: false,
        showFullscreenButton: false,
        showNextButton: true,
        showPrevButton: true,
        showThumbnailsButton: false
      },
      caption: {
        showCaption: false
      },
      thumbnails: {
        thumbnailsContainerBackgroundColor: "black",
        thumbnailsSize: ["75px !important", "75px !important"]
      }
    };


    const callbacks = {
      onLightboxOpened: object => {
        window.history.pushState(null, "lightbox", '#lb');
      },
      onLightboxClosed: object => { //does not fire after closeLightBox() im the Prompt
        window.history.back(); //this *does*  invoke closeLightBox() in the Prompt, but closing the lightbox if it is closed seems to be harmless
      }
    };
    var out;
    switch (this.state.status) {
      case "loading":
        out = <div className="photoloading">
          <CircularProgress />
        </div>;
        break;
      case "ok":
        const itemList = this.state.photos.map(function(item) {
          var url = "https://farm" + item.farm + ".staticflickr.com/" +
              item.server +"/" +item.id +"_" +item.secret +"_q.jpg";
          var name = item.title._content;
          var href ="https://farm" +item.farm +".staticflickr.com/" +
              item.server +"/" +item.id +"_" +item.secret +"_b.jpg";
          return (
              <a href={href} key={href} className="photolink" data-attribute="SRL">
                <img alt={name} src={url} />
              </a>
          );
        });
        out = <div>
          <WindowTitleComponent title={this.state.title}/>
          <SRLWrapper options={srloptions} callbacks={callbacks}>
            <Prompt
                message={(location, action) => {
                  if (document.getElementById("SRLLightbox") != null) {
                    this.closeLightbox();
                    return false;
                  } else {
                    return true;
                  }
                }}
            />
            <div className="photocontainer">{itemList}</div>
          </SRLWrapper>
        </div>;
        break;
      case "fail":
        out = <div>
          <Card className="paper-card login-card">
            <CardContent className="inlog-card">
              <p>
                Dit album bestaat helaas niet, of er is iets anders misgegaan. Klik <Link to="/photos">hier</Link> om terug te gaan naar het albumoverzicht.
              </p>
            </CardContent>
          </Card>
        </div>
        break;
      default:
        out = <div>
          <Card className="paper-card login-card">
            <CardContent className="inlog-card">
              <p>
                Er is iets misgegaan. Klik <Link to="/photos">hier</Link> om terug te gaan naar het albumoverzicht.
              </p>
            </CardContent>
          </Card>
        </div>
        break;
    }
    return(out);
  }
}

export default withBackHook(Album);
