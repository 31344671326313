import React, { useState, useEffect } from "react";
import Parse from "parse";

import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Snackbar,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { unescapeString } from "./fieldescaping";
import { getAllRegFields } from "./RegistrationFields";

const RegisterComponent = (props) => {
	const [wantDeleteOpen, setWantDeleteOpen] = useState(false);
	const [currentReg, setCurrentReg] = useState(null);
	const [snackbarWarning, setSnackbarWarning] = useState("");
	const [fields, setFields] = useState({});
	const [simpleRegistration, setSimpleRegistration] = useState(false);
	const [singleRemove, setSingleRemove] = useState(false);
	const [forceSave, setForceSave] = useState(false);
	const [waiting, setWaiting] = useState(false);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		if (props.event !== null && getRegFields(props.event)) {
			let fields = [];
			let simpleRegistration = true;
			let wantDeleteOpen = false;
			let currentReg = null;
			for (var reg of props.ownRegistrations) {
				if (reg.get("eventId") === props.event.id) {
					currentReg = reg;
					break;
				}
			}
			setCurrentReg(currentReg);
			if (
				props.event.get("teamRegistration") ||
				props.event.get("regDescription") !== "" ||
				props.event.get("noShowPenalty") > 0
			) {
				simpleRegistration = false;
			}
			for (var [field] of getRegFields(props.event)) {
				if (currentReg && currentReg.get("fields"))
					fields[field] = currentReg.get("fields")[field];
				else if (props.event.get("teamRegistration")) {
					fields[field] = "";
					fields["Teamlid 1"] = Parse.User.current().get("display_name");
				} else {
					fields[field] = "";
				}
				simpleRegistration = false;
			}
			if (simpleRegistration) {
				if (currentReg) {
					wantDeleteOpen = true;
				} else {
					handleSave();
				}
			}
			setFields(fields);
			setWantDeleteOpen(wantDeleteOpen);
			setSimpleRegistration(simpleRegistration);
		} else {
			setWantDeleteOpen(false);
			setCurrentReg(null);
			setFields([]);
			setSimpleRegistration(false);
			setWaiting(false);
		}
	}, [props.event]);

	const handleClose = () => {
		setSnackbarWarning(null);
		props.setRegisterEvent(null);
	};

	const validateAndSave = () => {
		const form = document.getElementById("register-form");
		const submit = document.getElementById("register-submit");
		if (!form.checkValidity()) {
			submit.click();
		} else {
			handleSave();
		}
	};

	const handleSave = () => {
		setWaiting(true);
		let user = Parse.User.current();
		let result = {};
		let isTeam = props.event.get("teamRegistration");
		let missingName = isTeam;
		let empty = true;
		for (const [field] of getRegFields(props.event)) {
			result[field] = fields[field];
			if (result[field]) {
				empty = false;
			}
			if (isTeam && field.match(/Teamlid \d+/)) {
				if (
					result[field] === user.get("username") ||
					result[field] === user.get("first_name") ||
					result[field] === user.get("last_name") ||
					result[field] === user.get("display_name")
				)
					missingName = false;
			}
		}
		if (isTeam && (missingName || empty)) {
			if (!empty) {
				setSingleRemove(result);
			}
			handleDelete();
		} else {
			saveRegistration(result, currentReg);
		}
	};

	const handleDelete = () => {
		setWantDeleteOpen(true);
	};

	const handleDeleteClose = () => {
		setWantDeleteOpen(false);
		if (simpleRegistration) {
			handleClose();
		}
	};

	const handleConfirmDelete = () => {
		if (props.event.get("teamRegistration") && singleRemove) {
			saveRegistration(singleRemove, currentReg);
		} else {
			deleteRegistration(currentReg);
		}
		handleDeleteClose();
		handleClose();
	};

	const handleChange = (e) => {
		let newfields = { ...fields };
		newfields[e.target.name] = e.target.value;
		setForceSave(false);
		setFields(newfields);
	};

	const getRegFields = (event) => getAllRegFields(event, true);

	const saveRegistration = (register, oldReg) => {
		let user = Parse.User.current();
		if (!user) return;

		if (register != null) {
			const Registration = Parse.Object.extend("Registration");
			let reg = oldReg;
			let isCreating = false;

			if (!oldReg) {
				reg = new Registration();
				isCreating = true;
			}

			reg.set("fields", register);

			if (isCreating) {
				var regACL = new Parse.ACL();
				regACL.setRoleWriteAccess("Admin", true);
				regACL.setRoleReadAccess("Admin", true);
				regACL.setWriteAccess(user.id, true);
				regACL.setReadAccess(user.id, true);
				if (props.event.get("uid")) {
					regACL.setReadAccess(props.event.get("uid"), true);
					regACL.setWriteAccess(props.event.get("uid"), true);
				}
				if (props.event.get("organizers")) {
					props.event.get("organizers").forEach((organizer) => {
						regACL.setReadAccess(organizer.id, true);
						regACL.setWriteAccess(organizer.id, true);
					});
				}
				regACL.setPublicReadAccess(false);
				reg.setACL(regACL);
				reg.set("user", user.get("display_name"));
				reg.set("uid", user.id);
				reg.set("eventId", props.event.id);
			}

			reg.save().then(
				(reg) => {
					let ownRegs = props.ownRegistrations;
					if (oldReg) {
						let index = ownRegs.indexOf(oldReg);
						ownRegs[index] = reg;
					} else {
						ownRegs.push(reg);
					}
					let users = reg.get("users") || [];
					let success = true;
					let fields = reg.get("fields");
					let warning = "";
					if (!forceSave) {
						for (let i = 0; i < users.length; i++) {
							if (!users[i] && fields["Teamlid " + (i + 1)]) {
								success = false;
								warning +=
									'Kon geen (unieke) gebruiker vinden met de naam "' +
									fields["Teamlid " + (i + 1)] +
									'"';
								setForceSave(true);
							}
						}
					}
					setFields(fields);
					if (success) {
						props.setRegisterEvent(null);
						setSnackbarWarning(null);
						setForceSave(false);
					} else {
						setSnackbarWarning(warning);
					}
					props.setRegistrations(ownRegs);
					setWaiting(false);
				},
				(error) => {
					setSnackbarWarning("Inschrijven niet gelukt: " + error.message);
					setWaiting(false);
				}
			);
		}
	};

	const deleteRegistration = (oldReg) => {
		if (oldReg) {
			//delete event
			let ownRegs = props.ownRegistrations;
			let index = ownRegs.indexOf(oldReg);
			ownRegs.splice(index, 1);
			props.setRegistrations(ownRegs);
			oldReg.destroy();
		}
	};

	const open = props.event !== null;
	const deleteDialog = (
		<Dialog
			open={wantDeleteOpen}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			onClose={handleDeleteClose}
			style={{ zIndex: 10000 }}
		>
			<DialogTitle id="alert-dialog-title">
				{"Inschrijving verwijderen?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{open && props.event.get("teamRegistration") && !singleRemove
						? "Weet u zeker dat u het HELE team wilt uitschrijven?"
						: "Weet u zeker dat u zich wilt uitschrijven?"}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleDeleteClose} color="primary">
					Annuleren
				</Button>
				<Button onClick={handleConfirmDelete} color="primary" autoFocus>
					Uitschrijven
				</Button>
			</DialogActions>
		</Dialog>
	);
	const snackbar = (
		<Snackbar
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			open={!!snackbarWarning}
			autoHideDuration={5000}
			onClose={() => {
				setSnackbarWarning(null);
			}}
			ContentProps={{
				"aria-describedby": "message-id",
			}}
			message={snackbarWarning}
			action={[
				<IconButton
					key="close"
					aria-label="Close"
					color="inherit"
					style={{ padding: "4px" }}
					onClick={() => {
						setSnackbarWarning(null);
					}}
					size="large"
				>
					<CloseIcon />
				</IconButton>,
			]}
		/>
	);

	if (props.event && simpleRegistration) {
		if (!currentReg) {
			return snackbar;
		} else {
			return deleteDialog;
		}
	}
	if (open) {
		return (
			<div>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
					classes={{ paper: "registration-dialog" }}
				>
					<form id="register-form">
						<DialogContent classes={{ root: "content-dialog" }}>
							<DialogTitle
								style={{ paddingTop: "0" }}
								id="responsive-dialog-title"
							>
								{" "}
								{currentReg != null ? (
									<div>Inschrijving bewerken</div>
								) : (
									<div> Inschrijven</div>
								)}
							</DialogTitle>
							<DialogContentText
								style={{ paddingLeft: "12px", paddingBottom: "0px" }}
							>
								{currentReg != null ? (
									<span> Bewerk je inschrijving voor</span>
								) : (
									<span> Schrijf je in voor</span>
								)}{" "}
								{props.event.get("title")}.
							</DialogContentText>
							<DialogContentText
								style={{ paddingLeft: "12px", paddingBottom: "0px" }}
							>
								{props.event.get("regDescription")}
							</DialogContentText>
							{props.event.get("registrationDeadline") &&
							props.event.get("noShowPenalty") > 0 ? (
								<DialogContentText
									style={{ paddingLeft: "12px", paddingBottom: "0px" }}
								>
									<span style={{ color: "red" }}>{"Let op! "}</span>
									{"Voor deze activiteit worden kosten gemaakt door Desda. Als je je na de deadline uitschrijft kan het zijn dat je naast de inschrijfkosten nog maximaal €" +
										Number(props.event.get("noShowPenalty"))
											.toFixed(2)
											.replace(".", ",") +
										" moet betalen."}
								</DialogContentText>
							) : null}
							<div className="flex-column">
								{getRegFields(props.event).map((x) => {
									let [i, personal] = x;
									let name = i;
									let req = name.at(-1) === "*";
									if (req) {
										name = name.slice(0, -1);
									}
									if (personal) {
										name = name + " (alleen zichtbaar voor organisatie)";
									}
									return (
										<TextField
											required={req}
											variant="outlined"
											label={unescapeString(name)}
											value={fields[i]}
											name={i}
											key={i}
											onChange={handleChange}
										/>
									);
								})}
							</div>
						</DialogContent>
						<DialogActions>
							{currentReg != null ? (
								<IconButton onClick={handleDelete} color="default" size="large">
									<DeleteIcon />
								</IconButton>
							) : null}

							{forceSave || <Button onClick={handleClose}>Annuleren</Button>}
							<Button
								type="submit"
								id="register-submit"
								style={{ display: "none" }}
							/>
							<Button
								onClick={validateAndSave}
								color="primary"
								autoFocus
								disabled={waiting}
							>
								{forceSave ? (
									<div>Toch opslaan</div>
								) : currentReg != null ? (
									<div>Inschrijving updaten</div>
								) : (
									<div> Inschrijven</div>
								)}
							</Button>
						</DialogActions>
					</form>
					{snackbar}
				</Dialog>
				{deleteDialog}{" "}
			</div>
		);
	} else {
		return null;
	}
};

export default RegisterComponent;
