import {FormControl, FormControlLabel, MenuItem, Select, Switch, TextField} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import Parse from "parse";


const queryEetlijstMenus = async () => {
    const ClassObject = Parse.Object.extend("EetlijstMenu");
    const query = new Parse.Query(ClassObject);
    const results = await query.find();
    const menus = [];
    let defaultMenu = 0;
    for (let obj of results) {
        if (obj.get("standard")) {
            defaultMenu = obj.id
        }
        menus.push({
            id: obj.id,
            name: obj.get("title"),
        });
    }
    return [defaultMenu, menus];
}

const SelectEetlijstComponent = props => {
    let event = props.event;
    let setEvent = props.setEvent;
    let handleChange = props.handleChange;

    const [eetlijstMenus, setEetlijstMenus] = useState([]);
    useEffect(async () => {
        const [defaultMenu, result] = await queryEetlijstMenus();
        setEetlijstMenus(result);
        if (defaultMenu && event.eetlijstMenu === 0) {
            setEvent({ eetlijstMenu: defaultMenu })
        }
    }, []);
    let handleEetlijstTimeChange = date => {
        const x = new Date(event.startDate.getTime());
        x.setHours(date.getHours());
        x.setMinutes(date.getMinutes());
        x.setSeconds(0);
        setEvent({ eetlijstTime: x });
    };
    let toggle = <FormControlLabel
        style={{ marginLeft: 0 }}
        control={
            <Switch
                color="primary"
                checked={event.hasEetlijst}
                onChange={handleChange("hasEetlijst", true)}
                value="hasEetlijst"
            />
        }
        label="Eetlijst"
    />;
    if (event.hasEetlijst) {
        return <>
            {toggle}
            <FormControl variant="standard" className="inner-flex-column">
                <TimePicker
                    onChange={handleEetlijstTimeChange}
                    renderInput={() => <TextField variant="standard" label="Eetlijst sluit om" /> }
                    value={event.eetlijstTime}
                    ampm={false}
                />
                <Select label="Bestellijst"
                    variant="outlined"
                    placeholder="Type een leuke beschrijving..."
                    value={event.eetlijstMenu}
                    onChange={handleChange("eetlijstMenu")}>
                    {eetlijstMenus.map(obj => (
                        <MenuItem value={obj.id} key={obj.id}>{obj.name}</MenuItem>
                    ))
                    }
                </Select>
            </FormControl>
        </>;
    }
    return toggle;
};

export default SelectEetlijstComponent;