import React from "react";
import { Route } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Toolbar from "react-big-calendar/lib/Toolbar";
import moment from "moment";
import "moment/locale/nl";

import {
	Dialog,
	Button,
	DialogActions,
	DialogContent,
	useTheme,
	useMediaQuery,
	IconButton,
} from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";

import { SettingsContext } from "../Settings";
import WindowTitleComponent from "../WindowTitleComponent";

import EventCard from "./EventCard";

import "./CalendarComponent.css";

class CalendarComponent extends React.Component {
	constructor(props) {
		super(props);
		moment.locale("nl");

		this.state = {
			open: false,
			view: "month",
			localizer: momentLocalizer(moment),
			messages: {
				today: "Vandaag",
				previous: "&#171;",
				next: "&#187;",
				month: "Maand",
				week: "Week",
				day: "Dag",
				agenda: "Agenda",
			},
		};
	}

	componentDidMount() {
		this.onNavigate(new Date());
	}

	componentWillUnmount() {
		if (this.props.updateAppTitle) {
			this.props.updateAppTitle("DesdApp");
		}
	}

	selectEvent = (event) => {
		this.props.routeProps.history.push("/calendar/" + event.id);
	};

	editEvent = (doc) => {
		this.props.editEvent(doc);
	};

	onNavigate = (nav) => {
		const month = moment(nav).format("MMMM YYYY");
		const Month = month.charAt(0).toUpperCase() + month.slice(1);
		if (this.props.updateAppTitle) {
			this.props.updateAppTitle(Month);
		}
	};

	onShowMore = () => {
		this.setState({ view: "day" });
	};

	onView = (view) => {
		this.setState({ view: view });
	};

	handleSelectSlot = ({ start, end }) => {
		if (start !== end) {
			//not just clicked
			const event = { startDate: start, endDate: end };
			this.props.createEvent(event);
		}
	};

	//Some styles are overridden in App.css
	render() {
		let filteredEvents = this.props.events.filter(
			(event) => !event.test || this.context.showTestContent
		);

		return (
			<div>
				<WindowTitleComponent title="Agenda" />{" "}
				<Calendar
					selectable
					localizer={this.state.localizer}
					events={filteredEvents}
					startAccessor="startDate"
					endAccessor="endDate"
					views={["month", "week", "day"]}
					scrollToTime={moment().set({ h: 8, m: 0 }).toDate()}
					view={this.state.view}
					onView={this.onView}
					className="desda-calendar"
					onSelectEvent={this.selectEvent}
					onSelectSlot={this.handleSelectSlot}
					messages={this.state.messages}
					components={{ toolbar: CalendarToolbar }}
					onNavigate={this.onNavigate}
					onShowMore={this.onShowMore}
				/>
				<Route
					path={"/calendar/:eventId"}
					render={(routeProps) => {
						return (
							<Event props={this.props} id={routeProps.match.params.eventId} />
						);
					}}
				/>
			</div>
		);
	}
}

class CalendarToolbar extends Toolbar {
	render() {
		const _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
		_interopRequireDefault(require("react"));
		const _constants = require("react-big-calendar/lib/utils/constants");
		const _this$props = this.props,
			messages = _this$props.localizer.messages; //label = _this$props.label;

		return (
			<div className="rbc-toolbar">
				<span className="rbc-btn-group">
					<button
						type="button"
						onClick={this.navigate.bind(null, _constants.navigate.PREVIOUS)}
						id="arrowleft"
					>
						&#171;
					</button>
					<IconButton
						id="today"
						onClick={this.navigate.bind(null, _constants.navigate.TODAY)}
						size="large"
					>
						<TodayIcon />
					</IconButton>
					<button
						type="button"
						onClick={this.navigate.bind(null, _constants.navigate.NEXT)}
						id="arrowright"
					>
						&#187;
					</button>
				</span>
				<span className="rbc-toolbar-label" style={{ padding: 0 }} />
				<span className="rbc-btn-group">{this.viewNamesGroup(messages)}</span>
			</div>
		);
	}
}

function EventDialog(props) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	let i = props.event;
	if (i === null || i === undefined) return null;
	else {
		return (
			<Dialog
				open={props.open}
				onClose={props.handleClose}
				aria-labelledby="form-dialog-title"
				scroll="paper"
				classes={{ paper: "event-dialog" }}
				fullScreen={fullScreen}
				maxWidth={null}
			>
				<DialogContent style={{ padding: "0" }}>
					<EventCard
						event={i}
						doc={i.doc}
						key={i.title + i.user}
						classes={{ card: "event-popup" }}
						expanded={true}
						editEvent={props.editEvent}
						user={props.user}
						isRegistered={props.isRegisteredFor(i.id)}
						handleRegisterEvent={props.handleRegisterEvent}
						handleEetlijstEvent={props.handleEetlijstEvent}
						hasEetlijst={i.hasEetlijst}
						makePost={props.makePost}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={props.handleClose} color="primary">
						Sluit
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

function Event({ props, id }) {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
	let found = false;
	let evt;
	for (evt of props.events) {
		if (evt.id === id) {
			found = true;
			break;
		}
	}
	if (!found && props.events != null && props.events.length > 0) {
		props.routeProps.history.push("/calendar");
		return null;
	}

	return (
		<div>
			<EventDialog
				handleRegisterEvent={props.handleRegisterEvent}
				handleEetlijstEvent={props.handleEetlijstEvent}
				isRegisteredFor={props.isRegisteredFor}
				event={evt}
				open={true}
				user={props.user}
				editEvent={props.editEvent}
				handleClose={() => {
					props.routeProps.history.goBack();
				}}
				fullScreen={fullScreen}
			/>
		</div>
	);
}

CalendarComponent.contextType = SettingsContext;

export default CalendarComponent;
