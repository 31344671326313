import React, { useState } from "react";
import Parse from "parse";

import {
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
	Snackbar,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import WindowTitleComponent from "./WindowTitleComponent";

function FeedbackComponent(props) {
	const [feedbackMessage, setFeedbackMessage] = useState("");
	const [snackMessage, setSnackMessage] = useState(null);

	const handleBug = (feedbackMessage) => {
		if (!feedbackMessage || feedbackMessage.length < 1) return;
		const Bug = Parse.Object.extend("Bug");
		let bug = new Bug();
		bug.set("message", feedbackMessage);
		bug.set("user", Parse.User.current());
		bug.save().then(
			(event) => {
				setSnackMessage(
					"Je feedback is succesvol doorgegeven aan de www-Cie! Dankjewel!"
				);
			},
			(error) => {
				setSnackMessage(
					"Oei, oei, oei... Er is iets fout gegaan bij het verzenden van een bugmelding. " +
						error.message
				);
			}
		);
		setFeedbackMessage("");
	};

	return (
		<>
			<WindowTitleComponent title="Feedback" />
			<Card className="paper-card login-card">
				<form>
					<CardContent className="inlog-card">
						<div className="inlog-card">
							<Typography gutterBottom variant="h5" component="h1">
								Feedback
							</Typography>
							<Typography component="p">
								Meld hier je feedback over de DesdApp. We horen graag over je
								ervaringen en ideeën. Als je een bug hebt gevonden, probeer zo
								duidelijk mogelijk te omschrijven wat je deed toen het fout
								ging, zodat wij het zo snel mogelijk kunnen oplossen.
							</Typography>
							<Typography component="p">
								Geef het aan als je op de hoogte gehouden wil worden. Dan sturen
								we je een mailtje (via het e-mailadres waarmee je een account
								hebt op de Desda-site).
							</Typography>
							<TextField
								id="bug"
								label="Feedback"
								value={feedbackMessage}
								onChange={(event) => setFeedbackMessage(event.target.value)}
								variant="outlined"
								margin="normal"
								multiline
								minRows="4"
							/>

							<Button
								className="login-button"
								variant="contained"
								color="primary"
								onClick={() => handleBug(feedbackMessage)}
							>
								Melden
							</Button>
						</div>
					</CardContent>
				</form>
			</Card>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackMessage != null}
				autoHideDuration={2000}
				onClose={() => {
					setSnackMessage(null);
				}}
				ContentProps={{
					"aria-describedby": "message-id",
				}}
				message={snackMessage}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						style={{ padding: "4px" }}
						onClick={() => {
							setSnackMessage(null);
						}}
						size="large"
					>
						<CloseIcon />
					</IconButton>,
				]}
			/>
		</>
	);
}

export default FeedbackComponent;
