import React from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


export default function HistoryBackButton() {
  let history = useHistory();
  return (
    <IconButton
      aria-label="Terug"
      aria-controls="menu-appbar"
      color="inherit"
      onClick={() =>{
        console.log(history.length)
        if(history.length > 1)
          history.goBack()
        else
          history.replace("/")
      } }
      size="large">
      <ArrowBackIcon />
    </IconButton>
  );
};
