import React from "react";

import WindowTitleComponent from "../WindowTitleComponent";

import AlbumList from "./AlbumList";
import Album from "./Album";
import "./PhotoComponent.css";


export default class PhotoComponent extends React.Component {

  componentDidMount() {
    this.props.updateAppTitle("Foto's");
  }

  componentDidUpdate(prevProps) {
    if(prevProps.albumId !==  this.props.albumId && ! this.props.albumId)
    {
      this.props.updateAppTitle("Foto's");
    }
  }


  componentWillUnmount() {
    this.props.updateAppTitle("DesdApp");
  }

  //Some styles are overridden in App.css
  render() {
    if (this.props.albumId == null) {
      return <AlbumList/>
    }
    else {
      return (
          <div>
            <WindowTitleComponent title="Foto's" />
            <Album
                updateAppTitle={this.props.updateAppTitle}
                album={this.props.albumId}
            />
          </div>
      );
    }
  }
}
