import React from "react";

import { Container, Zoom, Fab, IconButton, Snackbar } from "@mui/material";
import { withStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import WindowTitleComponent from "../WindowTitleComponent";
import { SettingsContext } from "../Settings";

import EditPostComponent from "./EditPostComponent"
import PostCard from "./PostCard";
import CommentsComponent from "./CommentsComponent";

const styles = () => ({
  container: {
    maxWidth: "780px"
  }
});

let Parse = null;
class De37Component extends React.Component {

  constructor(props) {
    super(props);
    Parse = this.props.Parse;
    this.prevScrollTop = 0;
    this.state = {
      postList: [],
      query: null,
      open: !!this.props.postEvent,
      extraKey: false,
      showFab: true,
      errorMessage: null
    };
  }

  hideOrShowFab = () => {
    let that = this;
    let scrollTop = document.documentElement.scrollTop;
    if (scrollTop > this.prevScrollTop) {
      if (that.state.showFab) that.setState({ showFab: false });
    } else if (scrollTop < this.prevScrollTop) {
      if (!that.state.showFab) {
        that.setState({ showFab: true });
      }
    }

    this.prevScrollTop = scrollTop;
  };

  handleSnackbarClose = () => {
    this.setState({errorMessage: null});
  }

  componentDidMount() {
    window.addEventListener("scroll", this.hideOrShowFab, false);
    this.queryPost();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.hideOrShowFab);
  }

  async queryPost() {
    let ClassObject = Parse.Object.extend("Post");

    let query = new Parse.Query(ClassObject);
    query.limit(1000);
    const results = await query.find();

    this.setState({ postList: results, query: query });
    this.subscription = await query.subscribe();

    this.subscription.on("create", object => {
      let postList = this.state.postList;
      let contains = false;
      for (let i of postList) {
        if (i.id === object.id) {
          contains = true;
        }
      }
      if (!contains) postList.push(object);
      this.setState({ postList: postList });
    });

    this.subscription.on("update", object => {
      let postList = this.state.postList;
      for (let i of postList) {
        if (i.id === object.id) {
          postList[postList.indexOf(i)] = object;
          break;
        }
      }
      this.setState({ postList: postList });
    });

    this.subscription.on("delete", object => {
      let postList = this.state.postList;
      for (let i of postList) {
        if (i.id === object.id) {
          postList.splice(postList.indexOf(i), 1);
        }
      }
      this.setState({ postList: postList });
    });
  }

  render() {
    let filteredPostList = this.state.postList;
    const closeEdit = () => {
      this.props.removePostEvent();
      this.setState({ open: false, editPost: null, extraKey: !this.state.extraKey });
    }
    return (
      <Container className={this.props.classes.container}>
        <WindowTitleComponent title="De 37" />
        {this.context.isAdmin || this.context.isCommissielid ? (
            <Zoom in={this.state.showFab} key="showFab" timeout={300}>
              <Fab color="primary" aria-label="Add" onClick={() => {this.setState({ open: true });}} className="add-fab">
                {" "}
                <AddIcon />
              </Fab>
            </Zoom>
        ) : null}
        <EditPostComponent
            dialog open={this.state.open}
            onClose={ closeEdit }
            upload={(result, msg) => {
              if (result) {
                closeEdit();
              } else {
                this.setState({errorMessage:msg});
              }
            }}
            events={this.props.events}
            post={this.state.editPost}
            key={this.state.editPost ? this.state.editPost.objectId : this.state.extraKey}
            postEvent={this.props.postEvent}
        />

        <div className="flex-column">
          {filteredPostList.map(i => {

            let post = i.toJSON();
            post.parse = i;
            if (post.test && !this.context.showTestContent) return null;
            if (this.props.cardId === undefined || post.objectId === this.props.cardId) {
              // using request animationframe makes sures this happens after layout update
              return (

                  <PostCard
                      user={this.props.user}
                      key={post.objectId}
                      routeProps={this.props.routeProps}
                      edit={(post) => { this.setState({ editPost: post, open: true }); requestAnimationFrame( () => window.scrollTo(0, 0)) }}
                      post={post}
                      commentsPreview={this.props.cardId === undefined}>
                  </PostCard>

              );
            } else {
              return null;
            }
          }).reverse()}
          {this.props.cardId !== undefined ? <><h3>Reacties</h3><CommentsComponent parentId={this.props.cardId} parentType="Post"/></> : null}
        </div>
        <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.state.errorMessage}
            autoHideDuration={6000}
            onClose={this.handleSnackbarClose}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{this.state.errorMessage}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                style={{ padding: "4px" }}
                onClick={this.handleSnackbarClose}
                size="large">
                <CloseIcon />
              </IconButton>
            ]}
        />
      </Container >
    );
  }
}
De37Component.contextType = SettingsContext;
export default withStyles(styles)(De37Component);
