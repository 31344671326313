import React, { useState } from "react";

import {
	Card,
	CardContent,
	Typography,
	TextField,
	Button,
	Snackbar,
	IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import WindowTitleComponent from "./WindowTitleComponent";

function BestuursbusComponent(props) {
	const [feedbackMessage, setFeedbackMessage] = useState("");
	const [snackMessage, setSnackMessage] = useState(null);

	const handleFeedbackMessage = (feedbackMessage) => {
		if (!feedbackMessage || feedbackMessage.length < 1) return;
		setFeedbackMessage("");
		const params = { message: feedbackMessage };
		fetch(
			"https://pg-app-g36mbvirrcr4jqzwa3y6e1eyuah3aj.scalabl.cloud/1/functions/addToBestuursbus",
			{
				body: JSON.stringify(params),
				credentials: "omit",
				headers: {
					"Content-Type": "application/json",
					"X-Parse-Application-Id": "Df64UIiwB6ix3y9rp10AQf6LMee4hQMT7sQYViBY",
					"X-Parse-Rest-Api-Key": "lO507tcZuwS408ZPxv3WDIINjBYiehaMezmMMQQX",
				},
				method: "POST",
			}
		)
			.then((r) => r.json())
			.then((res) => {
				let obj = JSON.parse(res.result);
				if (obj.result === "success") {
					setSnackMessage(
						"Je feedback is succesvol doorgegeven aan het bestuur! Dankjewel!"
					);
				} else {
					setSnackMessage(
						"Er is iets fout gegaan bij het verzenden van je feedback: " +
							obj.message
					);
					setFeedbackMessage(feedbackMessage);
				}
			})
			.catch((error) => {
				setSnackMessage(
					"Er is iets fout gegaan bij het verzenden van je feedback: " +
						error.message
				);
				setFeedbackMessage(feedbackMessage);
			});
	};

	return (
		<>
			<WindowTitleComponent title="Bestuursbus" />
			<Card className="paper-card login-card">
				<form>
					<CardContent className="inlog-card">
						<div className="inlog-card">
							<Typography gutterBottom variant="h5" component="h1">
								Bestuursbus
							</Typography>
							<Typography component="p">
								Hier kan je feedback naar het bestuur sturen. Je kan ons
								natuurlijk ook altijd mailen, whatsappen, of gewoon aanspreken
								in het Huygensgebouw. De feedback is standaard anoniem, zet je
								naam in het bericht als je die ook door wil geven.
							</Typography>
							<TextField
								label="Feedback"
								value={feedbackMessage}
								onChange={(event) => setFeedbackMessage(event.target.value)}
								variant="outlined"
								margin="normal"
								multiline
								minRows="4"
							/>

							<Button
								className="login-button"
								variant="contained"
								color="primary"
								onClick={() => handleFeedbackMessage(feedbackMessage)}
							>
								Versturen
							</Button>
						</div>
					</CardContent>
				</form>
			</Card>

			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				open={snackMessage != null}
				autoHideDuration={2000}
				onClose={() => {
					setSnackMessage(null);
				}}
				ContentProps={{
					"aria-describedby": "message-id",
				}}
				message={snackMessage}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						style={{ padding: "4px" }}
						onClick={() => {
							setSnackMessage(null);
						}}
						size="large"
					>
						<CloseIcon />
					</IconButton>,
				]}
			/>
		</>
	);
}

export default BestuursbusComponent;
