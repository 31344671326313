import React, { useState } from "react";
import Parse from "parse";

import { TextField, Button } from "@mui/material";


// object Comment {
//     string objectId
//     string content
//     string title
//     string authorId
//     string authorName
//     string parentType
//     string parentId
//     string nestedId
// }

const CreateCommentComponent = (props) => {

  const [message, setMessage] = useState("");

  const createNewComment = () => {

    if(message.length < 2) {
      //"Message should have actual content"
      return false;
    }

    const Comment = Parse.Object.extend("Comment");
    let newcomment = new Comment();
    newcomment.set("content", message);
    newcomment.set("authorId", Parse.User.current().id)
    newcomment.set("authorName", Parse.User.current().get("display_name"));
    newcomment.set("parentType", props.parentType);
    newcomment.set("parentId", props.parentId);
    newcomment.set("nestedId", props.nestedId);
    let acl = new Parse.ACL();
    acl.setPublicReadAccess(false);
    acl.setRoleReadAccess("Lid", true);
    acl.setRoleWriteAccess("Admin", true);
    acl.setWriteAccess(Parse.User.current(), true);
    newcomment.setACL(acl);
    newcomment.save().then(
        () => {
          setMessage("");
        },
        console.log
    );

  }

  return (
      <div style={props.style ? props.style : {marginBottom: 16}}>
        <TextField label="Plaats een reactie!" onChange={e => {setMessage(e.target.value)}} fullWidth={true} variant="outlined" multiline={true} value={message}/>
        <Button onClick={createNewComment} color="primary">
          Plaatsen
        </Button>
      </div>
  );
}

export default CreateCommentComponent;