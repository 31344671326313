import React, { useEffect, useState } from "react";
import Parse from "parse";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	FormControl,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	CircularProgress,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import WindowTitleComponent from "../WindowTitleComponent";
import printEetlijst from "./printEetlijst";

let subscription = undefined;

function EetlijstComponent(props) {
	const [bestelling, setBestelling] = useState(0);
	const [eetlijstMenu, setEetlijstMenu] = useState([]);
	const [eetlijst, setEetlijst] = useState([]);
	const [compiling, setCompiling] = useState("");
	const [compileError, setCompileError] = useState("");
	const [restaurant, setRestaurant] = useState(null);
	const [beschrijving, setBeschrijving] = useState(null);

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

	useEffect(() => {
		if (props.event !== null) {
			queryEetlijstMenu().then(({ menu, restaurant, beschrijving }) => {
				setEetlijstMenu(menu);
				setRestaurant(restaurant);
				setBeschrijving(beschrijving);
			});
			queryEetlijst();
		}
	}, [props.event]);

	async function queryEetlijstMenu() {
		if (!props.event) return { menu: [], restaurant: null, beschrijving: null };
		const ClassObject = Parse.Object.extend("EetlijstMenu");
		const query = new Parse.Query(ClassObject);
		const results = await query.find();
		let menu = [];
		let restaurant = "";
		let beschrijving = "";
		for (let obj of results) {
			if (props.event.get("eetlijstMenu") != null)
				if (obj.id === props.event.get("eetlijstMenu")) {
					for (let i of obj.get("lijst")) {
						const text =
							i.name +
							", €" +
							i.price.toLocaleString("nl-NL", { minimumFractionDigits: 2 });
						menu.push({ food: i.name, price: i.price, text: text });
					}
					restaurant = obj.get("restaurant");
					beschrijving = obj.get("beschrijving");
				}
		}
		return { menu, restaurant, beschrijving };
	}

	async function queryEetlijst() {
		if (!props.event) return;
		const query = new Parse.Query("EetlijstBestelling");
		query.equalTo("eventId", props.event.id);
		let list = await query.findAll({});

		const sortfunc = (a, b) => {
			if (a.get("displayName") === Parse.User.current().get("display_name")) {
				if (b.get("displayName") === Parse.User.current().get("display_name"))
					return 0;
				return -1;
			} else if (
				b.get("displayName") === Parse.User.current().get("display_name")
			)
				return 1;
			return a.get("displayName") < b.get("displayName")
				? -1
				: +(a.get("displayName") > b.get("displayName"));
		};
		list.sort(sortfunc);

		setEetlijst(list);

		if (subscription) {
			subscription.unsubscribe();
		}

		subscription = await query.subscribe();

		subscription.on("create", (object) => {
			let list = eetlijst;
			let contains = false;
			for (let i of list) {
				if (i.id === object.id) {
					contains = true;
				}
			}
			if (!contains) list.unshift(object);
			list.sort(sortfunc);
			setEetlijst(list);
		});

		subscription.on("delete", (object) => {
			let list = eetlijst;
			for (let i of list) {
				if (i.id === object.id) {
					list.splice(list.indexOf(i), 1);
					// bestelling deleted
				}
			}
			setEetlijst(list);
		});
	}

	const handleClose = () => {
		if (subscription) {
			subscription.unsubscribe();
			subscription = null;
		}
		setBestelling(0);
		setEetlijstMenu([]);
		setRestaurant("");
		setBeschrijving("");

		props.close();
	};

	const handleDelete = (event) => {
		const id = event.currentTarget.value;
		const events = eetlijst.filter((x) => x.id === id);
		if (events.length === 1) {
			events[0].destroy();
		}
	};

	//onnodig ingewikkeld, maar wel leuk
	const handlePrintClick = () => {
		setCompiling("Eetlijst compileren...");
		printEetlijst(props.event, eetlijstMenu)
			.catch((err) => {
				compileError = err;
			})
			.finally(() => {
				setCompiling("");
				clearInterval(interval);
			});
	};

	const updateBestelling = (event) => {
		if (event.target) setBestelling(event.target.value);
	};

	const saveBestelling = () => {
		let user = Parse.User.current();
		let food = eetlijstMenu[bestelling].food;
		let price = eetlijstMenu[bestelling].price;
		if (!user || !food || !price) return;

		const Bestelling = Parse.Object.extend("EetlijstBestelling");
		let best = new Bestelling();
		best.set("food", food);
		best.set("price", price);

		const bestACL = new Parse.ACL();
		bestACL.setRoleWriteAccess("Admin", true);
		bestACL.setWriteAccess(user.id, true);
		bestACL.setRoleReadAccess("Lid", true);
		bestACL.setPublicReadAccess(false);
		best.setACL(bestACL);
		best.set("displayName", user.get("display_name"));
		best.set("uid", user.id);
		best.set("eventId", props.event.id);

		best.save().catch((error) => {
			// Execute any logic that should take place if the save fails.
			// error is a Parse.Error with an error code and message.
			alert(
				"Failed to create new Bestelling, with error code: " + error.message
			);
		});
	};

	const formatTime = (date) =>
		("0" + date.getHours()).slice(-2) +
		":" +
		("0" + date.getMinutes()).slice(-2);

	const open = props.event != null;
	const smallpadding = { paddingLeft: "8px", paddingRight: "8px" };
	if (open) {
		return (
			<div>
				<Dialog open={compiling !== ""}>
					<DialogContent style={{ paddingBottom: 20 }}>
						<div style={{ display: "flex" }}>
							<CircularProgress />
							<Typography style={{ alignSelf: "center", marginLeft: 20 }}>
								{compiling}
							</Typography>
						</div>
					</DialogContent>
				</Dialog>
				<Dialog open={compileError !== ""}>
					<DialogContent style={{ paddingBottom: 20 }}>
						<Typography style={{ alignSelf: "center", marginLeft: 20 }}>
							{compileError.split(" : ")[0]}
						</Typography>
					</DialogContent>
					<DialogActions>
						{compileError.startsWith("Popup") && (
							<Button href={compileError.split(" : ")[1]} target="_blank">
								Open PDF
							</Button>
						)}
						<Button
							onClick={() => {
								setCompileError("");
							}}
							autoFocus
						>
							Sluiten
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					fullScreen={fullScreen}
					open={open}
					onClose={handleClose}
					aria-labelledby="responsive-dialog-title"
					classes={{ paper: "eetlijst-dialog" }}
				>
					<DialogContent classes={{ root: "content-dialog" }}>
						<WindowTitleComponent title={"Eetlijst"} />
						<DialogTitle
							style={{ paddingTop: "0" }}
							id="responsive-dialog-title"
						>
							{<div>Eetlijst</div>}
						</DialogTitle>
						<DialogContentText
							style={{ paddingLeft: "12px", paddingBottom: "0px" }}
						>
							{<span>De eetlijst sluit om</span>}{" "}
							{formatTime(props.event.get("eetlijstTime"))}.
						</DialogContentText>
						<DialogContentText
							style={{ paddingLeft: "12px", paddingBottom: "0px" }}
						>
							{<span>Het eten wordt besteld bij</span>} {restaurant}.
						</DialogContentText>
						<DialogContentText
							style={{ paddingLeft: "12px", paddingBottom: "0px" }}
						>
							{
								<span>
									De weergegeven prijzen zijn een indicatie, de werkelijke
									prijzen kunnen hiervan afwijken.
								</span>
							}
						</DialogContentText>
						<DialogContentText
							style={{ paddingLeft: "12px", paddingBottom: "0px" }}
						>
							{beschrijving}
						</DialogContentText>
						<div id="eetlijstSelectRow">
							<FormControl variant="standard" style={{ width: "400px" }}>
								<Select
									native
									label="Bestellijst"
									variant="outlined"
									placeholder="Kies hier je eten"
									value={bestelling}
									onChange={updateBestelling}
								>
									{eetlijstMenu.map((item, index) => {
										return (
											<option value={index} key={index}>
												{item.text}
											</option>
										);
									})}
								</Select>
							</FormControl>
							<Button
								className="bestel-button"
								variant="contained"
								color="primary"
								onClick={saveBestelling}
							>
								Bestel
							</Button>
						</div>
						<div className="tableContainer">
							<Table>
								<TableHead>
									<TableRow>
										<TableCell padding="none" style={smallpadding} />
										<TableCell style={smallpadding}>Naam</TableCell>
										<TableCell style={smallpadding}>Eten</TableCell>
										<TableCell style={smallpadding}>Prijs</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{eetlijst.map((row, i) => (
										<TableRow key={row.id}>
											<TableCell padding="none" style={smallpadding}>
												{i + 1}.
											</TableCell>
											<TableCell scope="row" style={smallpadding}>
												{row.get("displayName")}
											</TableCell>
											<TableCell scope="row" style={smallpadding}>
												{row.get("food")}
											</TableCell>
											<TableCell scope="row" style={smallpadding}>
												{"€" +
													row.get("price").toLocaleString("nl-NL", {
														minimumFractionDigits: 2,
													})}
											</TableCell>
											<TableCell style={smallpadding}>
												{row.get("uid") === Parse.User.current().id ? (
													<IconButton
														onClick={handleDelete}
														value={row.id}
														color="default"
														size="large"
													>
														<DeleteIcon />
													</IconButton>
												) : null}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</div>
					</DialogContent>
					<DialogActions style={{ justifyContent: "space-between" }}>
						{props.isCL ? (
							<Button onClick={handlePrintClick} autoFocus>
								Printen
							</Button>
						) : null}
						<Button onClick={handleClose} autoFocus>
							Sluiten
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	} else return null;
}

export default EetlijstComponent;
